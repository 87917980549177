/** Reference:
 * https://onedegreehk.sharepoint.com/:x:/s/ODInsurancePlatform/EU_WPfak9V5PotxdXlMpTYABRJdyt0y5ReeNnkdEdUdDkQ?e=BKZoab
 * https://www.notion.so/aifintech/API-Error-Code-c849c5d8884546b3a267cbdb3ce47e40
 * https://www.notion.so/aifintech/Application-The-user-can-manually-create-the-application-with-a-form-6ac13f1c489347ba8fc744f0b106e407
 */

export const STATUS_SUCCESS = 0;
export const STATUS_UNKNOWN_ERROR = 1;

// general
export const ACCESS_TOKEN_ERROR = 980101;

// auth
export const LOGIN_STATUS_FAILED_NO_USER = 980102;
export const LOGIN_STATUS_FAILED_WRONG_PASSWORD = 980103;
export const LOGIN_STATUS_FAILED_USER_LOCKED_BY_RETRY_LOGIN_FAILED = 980104;
export const LOGIN_STATUS_FAILED_USER_IS_LOCKED = 980105;
export const LOGIN_STATUS_FAILED_USER_NOT_ACTIVE = 980106;
export const LOGIN_STATUS_FAILED_PASSWORD_EXPIRED = 980107;

export const BASE__AUTH__RESET_PASSWORD_TOKEN_NOT_FOUND = 980108;
export const BASE__AUTH__INVALID_PASSWORD_FORMAT = 980109;
export const BASE__AUTH__PASSWORD_USED_BEFORE = 980110;
export const BASE__AUTH__UPDATE_PASSWORD_TOKEN_NOT_FOUND = 980111;
export const BASE__AUTH__INCORRECT_OLD_PASSWORD = 980112;

// member
export const MEMBER_ALREADY_EXISTS = 902003;
export const SAME_MEMBER_CHANGE_REQUEST_UNDER_REVIEW = 902004;
export const MEMBER_INFO_HIT_BLACKLIST = 902005;

// claim
export const CLAIM_PREVIOUS_CLAIM_PROCESSING = 910201;
export const CLAIM_PREVIOUS_CLAIM_NOT_STABLE = 910202;
export const CLAIM_LINE_CANNOT_BE_REMOVED = 910203;
export const CLAIM_LINE_APPROVED_AMOUNT_CANNOT_BE_LOWER = 910204;
export const PLAN_AGGREGATE_LIMIT_EXCEED = 910205;
export const PLAN_LIFETIME_LIMIT_EXCEED = 910206;
export const BENEFIT_UTILIZATION_LIMIT_EXCEED = 910207;
export const BENEFIT_REIMBURSEMENT_LIMIT_EXCEED = 910208;

// campaign
export const CAMPAIGN_NUMBER_UNIQUE_ERROR_CODE = 910104;
export const CAMPAIGN_IMPORT_PROMO_CODES_PAGE_ERROR_CODE = 910101;
export const CAMPAIGN_IMPORT_PROMO_CODES_HEADER_ERROR_CODE = 910102;
export const CAMPAIGN_LAUNCH_FAILED = 910106;

// network
export const SERVICE_PROVIDER_MANAGERS_LIMIT_EXCEED_ERROR_CODE = 910301;
export const SERVICE_PROVIDER_TAGS_LIMIT_EXCEED_ERROR_CODE = 910302;
export const SERVICE_PROVIDER_CODE_EXIST_ERROR_CODE = 910303;

// plan
export const PLAN_CODE_EXIST = 910402;

// product
export const PRODUCT_CODE_DUPLICATED = 910504;
export const PRODUCT_VERSION_CODE_DUPLICATED = 910505;
export const PRODUCT_POLICY_PARTY_CONSTRAINT_DUPLICATED = 980201;

// attribute library / insured subject name
export const ATTRIBUTE_TEMPLATE_NAME_CANNOT_BE_EMPTY = 910601;
export const ATTRIBUTE_TEMPLATE_API_FIELD_NAME_CANNOT_BE_EMPTY = 910602;
export const INVALID_ATTRIBUTE_ENUM_AMOUNT = 910603;
export const INSURED_SUBJECT_NAME_CANNOT_BE_EMPTY = 910604;
export const ATTRIBUTE_TEMPLATE_NAME_EXIST = 910605;
export const ATTRIBUTE_TEMPLATE_API_FIELD_EXIST = 910606;
export const ATTRIBUTE_TEMPLATE_GROUP_NAME_EXIST = 910607;
export const INVALID_ATTRIBUTE_TEMPLATE_API_FIELD_NAME_FORMAT = 910608;
export const DUPLICATE_ATTRIBUTE_ENUM = 910609;
export const INVALID_ATTRIBUTE_TEMPLATE_NAME_LENGTH = 910610;
export const INVALID_ATTRIBUTE_TEMPLATE_API_FIELD_NAME_LENGTH = 910611;
export const ATTRIBUTE_IS_IN_USED = 910613;
export const ATTRIBUTE_IS_USED_IN_RULE = 910615;

// tax library
export const LIBRARY_TAX_CODE_DUPLICATED = 911101;
export const LIBRARY_TAX_VERSION_CODE_DUPLICATED = 911102;

// rule
export const DUPLICATE_RULE_CODE = 910701;

// question
export const ATTRIBUTE_IS_USED_IN_QUESTION = 910801;

// application
export const APPLICATION_VALIDATION_FAIL = 911301;
export const APPLICATION_POLICY_IS_INVALID = 911001;
export const INVALID_POLICY_PLAN_END_TIME = 911002;
export const INVALID_PLAN_POLICY_PERIOD = 911304;
export const NO_DEFAULT_FORM = 911015;
export const APPLICATION_INCOMPATIBLE_WITH_FORM = 911016;

// application form
export const ATTRIBUTE_BOOLEAN_DATA_TYPE_MISMATCH = 910705;
export const ATTRIBUTE_NUMERIC_DATA_TYPE_MISMATCH = 910706;
export const ATTRIBUTE_ENUM_DATA_TYPE_MISMATCH = 910707;
export const ATTRIBUTE_REFERENCE_DATA_ID_MISMATCH = 910708;
export const ATTRIBUTE_INTEGER_DATA_TYPE_MISMATCH = 910714;
export const ATTRIBUTE_DATETIME_DATA_TYPE_MISMATCH = 910716;
export const ATTRIBUTE_DATE_DATA_TYPE_MISMATCH = 910717;
export const ATTRIBUTE_ARRAY_DATA_TYPE_MISMATCH = 910721;
export const ATTRIBUTE_STRING_DATA_TYPE_MISMATCH = 910722;
export const ATTRIBUTE_NOT_IN_CONSTRAINT = 100301;
export const ATTRIBUTE_DATA_VALIDATION_ERROR = 910725;
export const RUN_RULE_FAILED = 100401;
export const APPLICATION_VALIDATION_CURRENCY_NOT_FOUND = 911311;
export const APPLICATION_PLAN_VALIDATION_INVALID_COINSURANCE_OPTION = 911331;
export const APPLICATION_PLAN_VALIDATION_INVALID_DEDUCTIBLE_OPTIONS = 911332;
export const APPLICATION_PLAN_VALIDATION_INVALID_PAYMENT_MODE = 911330;
export const APPLICATION_PLAN_VALIDATION_INVALID_ON_DEMAND_PAYMENT_FREQUENCY = 911329;
export const APPLICATION_VALIDATION_PLAN_END_TIME_IS_NONE = 911322;
export const APPLICATION_VALIDATION_PLAN_EFFECTIVE_TIME_IS_NONE = 911323;
export const APPLICATION_PLAN_VALIDATION_INVALID_AGGREGATE_LIMIT = 911328;
export const APPLICATION_VALIDATION_CURRENCY_NOT_IN_PLANS = 911314;
export const APPLICATION_VALIDATION_CORE_PLAN_NOT_FOUND = 911316;
export const APPLICATION_VALIDATION_PLAN_PAYMENT_PERIOD_NOT_IDENTICAL = 911318;
export const APPLICATION_VALIDATION_PLAN_EFFECTIVE_TIME_NOT_IDENTICAL = 911319;
export const APPLICATION_VALIDATION_CORE_PLAN_END_TIME_NOT_IDENTICAL = 911320;
export const APPLICATION_VALIDATION_RIDER_PLAN_END_TIME_GREATER_THAN_CORE = 911321;
export const APPLICATION_POLICYPARTY_VALIDATION_BENEFICIARY_LEGAL_HEIR_LIMIT = 910302;
export const APPLICATION_POLICYPARTY_VALIDATION_BENEFICIARY_RATES = 910303;
export const APPLICATION_VALIDATION_INVALID_PRODUCT = 911006;
export const APPLICATION_POLICYPARTY_VALIDATION_NO_PRIMARY_BENEFICIARY = 910304;
export const APPLICATION_PLAN_NOT_APPROVED = 911003;
export const APPLICATION_PLAN_NOT_RENEWABLE = 911004;
export const APPLICATION_APPLICANT_COUNT_MISMATCH = 910305;
export const APPLICATION_PRIMARY_POLICYHOLDER_COUNT_MISMATCH = 910306;
export const APPLICATION_SECONDARY_POLICYHOLDER_COUNT_MISMATCH = 910307;
export const APPLICATION_BENEFICIARY_COUNT_MISMATCH = 910308;
export const APPLICATION_BENEFICIARY_RATE_IS_REQUIRED = 910313;
export const APPLICATION_BENEFICIARY_TYPE_IS_REQUIRED = 910314;
export const APPLICATION_BENEFICIARY_IS_PRIMARY_IS_REQUIRED = 910312;
export const APPLICATION_BENEFICIARY_INSURED_RELATIONSHIP_IS_REQUIRED = 910315;
export const APPLICATION_INSURED_SUBJECT_COUNT_MISMATCH = 910309;
export const APPLICATION_POLICYPARTY_VALIDATION_BENEFICIARY_TYPE = 910310;
export const APPLICATION_BENEFICIARY_INVALID_INFORMATION = 910311;

export const APPLICATION_PLAN_START_TIME_BEFORE_LAUNCH_TIME = 911344;
export const APPLICATION_PLAN_START_TIME_EXCEED_SHELVED_TIME = 911341;

export const APPLICATION_VALIDATION_TEXT_CANNOT_EXCEED_MAX_LENGTH = 911358;

// endorsement
export const ENDORSEMENT_INSURED_SUBJECT_COUNT_MISMATCH = 910318;
export const ENDORSEMENT_BENEFICIARY_COUNT_MISMATCH = 910317;
export const ENDORSEMENT_NO_PRIMARY_BENEFICIARY = 910319;
