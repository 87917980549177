import { Epic } from 'redux-observable';
import { EMPTY, from, of } from 'rxjs';
import { catchError, concatMap, filter, map, startWith, takeUntil } from 'rxjs/operators';

import { ALERT_TYPE } from '@/constants/alert';
import { pushAlert } from '@/redux/MiscProvider/actions';
import { asyncUploadAttachment } from '@/services/IxtApi/hooks/attachment';

import { handleSessionTimeout } from '../AuthProvider/actions';
import {
  cancelAllUploadRemarkAttachment,
  retryUploadRemarkAttachment,
  startUploadRemarkAttachment,
  uploadRemarkAttachment,
  uploadRemarkAttachmentError,
  uploadRemarkAttachmentSuccess,
} from './actions';
import { isUploadTaskPendingSelector, uploadTaskDataSelector } from './selectors';

const uploadRemarkAttachmentEpic: Epic = (action$, state$) =>
  action$.pipe(
    filter((action): action is ReturnType<typeof uploadRemarkAttachment | typeof retryUploadRemarkAttachment> =>
      [uploadRemarkAttachment, retryUploadRemarkAttachment].some(({ match }) => match(action))
    ),
    concatMap(({ payload: { id } }) => {
      const data = uploadTaskDataSelector(id)(state$.value);
      const isPending = isUploadTaskPendingSelector(id)(state$.value);
      return data && isPending
        ? from(asyncUploadAttachment(data)).pipe(
            map(response => uploadRemarkAttachmentSuccess({ id, data: response })),
            takeUntil(action$.pipe(filter(cancelAllUploadRemarkAttachment.match))),
            catchError(error => of(uploadRemarkAttachmentError({ id, error }), handleSessionTimeout(error))),
            startWith(startUploadRemarkAttachment({ id }))
          )
        : EMPTY;
    })
  );

const accessRemarkFailEpic: Epic = action$ =>
  action$.pipe(
    filter(uploadRemarkAttachmentError.match),
    map(() => pushAlert({ type: ALERT_TYPE.FAIL, text: 'general.text_snackbar_error_network_error' }))
  );

export default [uploadRemarkAttachmentEpic, accessRemarkFailEpic];
