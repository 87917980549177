import { Component } from 'react';
import { createPortal } from 'react-dom';

class Portal extends Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    document.body.appendChild(this.el);
  }

  componentWillUnmount() {
    document.body.removeChild(this.el);
  }

  render() {
    return this.el ? createPortal(this.props.children, this.el) : null;
  }
}

export default Portal;
