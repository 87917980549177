export enum API_ERROR {
  UNKNOWN_ERROR = 'unknown_error',
}

const hasErrorCode = <T extends { code?: number }>(value: T) => {
  if ('code' in value) return typeof value.code === 'number';
  return false;
};

export class ApiResponseError<TRawData extends { code?: number }> extends Error {
  /**
   * BE customized raw error response
   */
  public data: TRawData;

  /**
   * BE customized error code, set to null if not have/receive any response
   */
  public code: number;

  /*
   * httpStatusCode is like 4xx or 5xx
   */
  public httpStatusCode: number;

  /*
   * Defined by specification
   */
  public type: API_ERROR;

  public constructor(httpStatusCode: number, data: TRawData, message?: string) {
    super(message);
    this.name = `ApiResponseError: http status code ${httpStatusCode}`;
    this.data = data;
    this.httpStatusCode = httpStatusCode;
    this.code = hasErrorCode(data) ? data.code ?? 0 : 0;
    /* TODO: define error type by response error code */
    this.type = API_ERROR.UNKNOWN_ERROR;
  }
}

interface IApiResponseClientError {
  error_code: number;
  message: string;
  error_fields: string[];
}

// eslint-disable-next-line max-classes-per-file
export class ApiResponseClientError<TRawResponse = IApiResponseClientError> extends Error {
  private rawResponse: TRawResponse;

  public constructor(rawResponse: TRawResponse, message?: string) {
    super(message);
    this.rawResponse = rawResponse;
  }

  public get rawResponseValue() {
    return this.rawResponse;
  }
}
