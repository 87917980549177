import getConfig from 'next/config';

function getEnvVar(name: string): string | undefined;
function getEnvVar(name: string, fallback: string): string;
function getEnvVar(name: string, fallback?: string): string | undefined {
  const { publicRuntimeConfig } = getConfig() || {};

  return process.env[name] || (publicRuntimeConfig && publicRuntimeConfig[name]) || fallback;
}

export default getEnvVar;
