import { StatusCodes } from 'http-status-codes';
import { JsonPrimitive } from 'type-fest';

import { ACCESS_TOKEN_ERROR, STATUS_SUCCESS, STATUS_UNKNOWN_ERROR } from '@/constants/apiStatusCode';
import { ApiResponseClientError, ApiResponseServerError } from '@/constants/errors';
import { handleInvalidAccessToken } from '@/redux/AuthProvider/actions';
import { IXT_API_URL } from '@/services/OneDegree/constants';
import {
  AuthApiResponse,
  InternalApiResponse,
  MutationRequestPayload,
  MutationType,
  ServiceApiResponse,
  StringifyQueryKey,
} from '@/services/ReactQuery/interfaces';
import { getFetchMutationOptions, getFetchQueryOptions, getServiceQueryUrl } from '@/services/ReactQuery/utils';
import downloadBlob from '@/utils/downloadBlob';
import getStore from '@/utils/getStore';

const authErrorCodes = [StatusCodes.UNAUTHORIZED, StatusCodes.FORBIDDEN];

export const hasStatusCode = (response: any): response is InternalApiResponse => {
  return !!response && typeof response === 'object' && 'status' in response && response.status !== STATUS_UNKNOWN_ERROR;
};

export const hasAuthError = (rawResponse: Response, response: any): response is AuthApiResponse => {
  if (authErrorCodes.includes(rawResponse.status)) {
    return 'code' in response ? response.code.toString()[0] === '2' : false;
  }
  return false;
};

const isClientError = (rawResponse: Response) => {
  return rawResponse.status.toString()[0] === '4';
};

export const defaultQueryFn = async <TResponseData = unknown>(
  url: string,
  headers?: Record<string, string>
): Promise<TResponseData> => {
  try {
    const rawResponse = await fetch(url, getFetchQueryOptions(headers));
    const response: InternalApiResponse<TResponseData> | AuthApiResponse = await rawResponse.json();

    if (rawResponse.ok && 'data' in response && response.data && response.status === STATUS_SUCCESS) {
      return response.data;
    }

    const defaultMessage = `${rawResponse.status}: ${rawResponse.statusText}`;

    // TODO: refactor to throw `ApiAuthError` instead
    /* 401 and 403 API error should be `ApiResponseClientError` */
    if (hasAuthError(rawResponse, response)) {
      throw new ApiResponseClientError(rawResponse, rawResponse.statusText);
    }

    /**
     * 4xx API error should have status code to display error so we can use `ApiResponseClientError`
     * 5xx API error just throw `ApiResponseServerError` that contains the rawResponse (no status code expected)
     */
    if (hasStatusCode(response)) {
      throw new ApiResponseClientError(response, 'message' in response ? response.message : defaultMessage);
    } else if (isClientError(rawResponse)) {
      throw new ApiResponseClientError(rawResponse, rawResponse.statusText);
    } else {
      throw new ApiResponseServerError(response, defaultMessage);
    }
  } catch (error) {
    if (error instanceof ApiResponseClientError && Number(error.code) === ACCESS_TOKEN_ERROR) {
      getStore()?.dispatch(handleInvalidAccessToken());
    }
    throw error;
  }
};

interface IxtApiQueryFnParams<TQuery extends Record<string, unknown> = Record<string, string | number>> {
  resourceUrl: string;
  version?: 'v1' | 'internal';
  query?: TQuery;
  headers?: Record<string, string>;
}

export const ixtApiQueryFn = async <
  TResponseData = unknown,
  TQuery extends Record<string, JsonPrimitive> = Record<string, JsonPrimitive>,
  TError = unknown
>({
  resourceUrl,
  version = 'v1',
  query,
  headers,
}: IxtApiQueryFnParams<TQuery>) => {
  const url = new URL(`${resourceUrl.startsWith('/') ? `/${version}` : `/${version}/`}${resourceUrl}`, IXT_API_URL);

  if (query) {
    Object.entries(query).forEach(([key, value]) => {
      if (value) {
        url.searchParams.append(key, value.toString());
      }
    });
  }

  const response = await fetch(url.toString(), getFetchQueryOptions(headers));
  const data = await response.json();

  if (response.ok) {
    return data as TResponseData;
  }
  // eslint-disable-next-line @typescript-eslint/no-throw-literal
  throw data as TError;
};

export const ixtResourceQueryFn = async <TResponseData = unknown>(
  url: string,
  headers?: Record<string, string>
): Promise<TResponseData> => {
  const rawResponse = await fetch(url, getFetchQueryOptions(headers));
  const response = await rawResponse.json();

  if (rawResponse.ok && 'results' in response && response.results) {
    return response.results;
  }

  if (rawResponse.ok && !('results' in response)) {
    return response;
  }

  const defaultMessage = `${rawResponse.status}: ${rawResponse.statusText}`;

  /* 401 and 403 API error should be `ApiResponseClientError` */
  if (hasAuthError(rawResponse, response)) {
    throw new ApiResponseClientError(rawResponse, rawResponse.statusText);
  }

  if (hasStatusCode(response)) {
    throw new ApiResponseClientError(response, 'message' in response ? response.message : defaultMessage);
  } else if (isClientError(rawResponse)) {
    throw new ApiResponseClientError(rawResponse, rawResponse.statusText);
  } else {
    throw new ApiResponseServerError(response, defaultMessage);
  }
};

export const authQueryFn = async <TResponseData extends object>(
  apiService: string,
  resourceUri: StringifyQueryKey,
  searchParams?: Record<string, string>,
  headers?: Record<string, string>
): Promise<TResponseData> => {
  try {
    const url = getServiceQueryUrl(apiService, resourceUri, searchParams);
    const rawResponse = await fetch(url, headers ? { headers } : undefined);
    const response: ServiceApiResponse<TResponseData> = await rawResponse.json();

    if (rawResponse.ok && 'results' in response && response.results) {
      return response.results;
    }

    if (rawResponse.ok && !('results' in response)) {
      return response;
    }

    const defaultMessage = `${rawResponse.status}: ${rawResponse.statusText}`;

    // TODO: refactor to throw `ApiAuthError` instead
    /* 401 and 403 API error should be `ApiResponseClientError` */
    if (hasAuthError(rawResponse, response)) {
      throw new ApiResponseClientError(rawResponse, rawResponse.statusText);
    }

    if (hasStatusCode(response)) {
      throw new ApiResponseClientError(response, 'message' in response ? response.message : defaultMessage);
    } else if (isClientError(rawResponse)) {
      throw new ApiResponseClientError(rawResponse, rawResponse.statusText);
    } else {
      throw new ApiResponseServerError(response, defaultMessage);
    }
  } catch (error) {
    throw error;
  }
};

export const defaultDownloadFn = async (url: string, headers?: Record<string, string>) => {
  try {
    const rawResponse = await fetch(url, getFetchQueryOptions(headers));
    if (rawResponse.ok) {
      return await rawResponse.blob();
    }

    const response = await rawResponse.json();
    const defaultMessage = `${rawResponse.status}: ${rawResponse.statusText}`;

    // TODO: refactor to throw `ApiAuthError` instead
    /* 401 and 403 API error should be `ApiResponseClientError` */
    if (hasAuthError(rawResponse, response)) {
      throw new ApiResponseClientError(rawResponse, rawResponse.statusText);
    }

    /**
     * 4xx API error should have status code to display error so we can use `ApiResponseClientError`
     * 5xx API error just throw `ApiResponseServerError` that contains the rawResponse (no status code expected)
     */
    if (hasStatusCode(response)) {
      throw new ApiResponseClientError(response, 'message' in response ? response.message : defaultMessage);
    } else if (isClientError(rawResponse)) {
      throw new ApiResponseClientError(rawResponse, rawResponse.statusText);
    } else {
      throw new ApiResponseServerError(response, defaultMessage);
    }
  } catch (error) {
    if (error instanceof ApiResponseClientError && Number(error.code) === ACCESS_TOKEN_ERROR) {
      getStore()?.dispatch(handleInvalidAccessToken());
    }
    throw error;
  }
};

export const defaultMutationFn = async <TRequest = MutationRequestPayload, TResponseData = void>(
  resourceUri: string,
  method: MutationType,
  request?: TRequest,
  headers?: Record<string, string>
): Promise<TResponseData> => {
  try {
    const url = new URL(`${resourceUri.startsWith('/') ? '/internal' : '/internal/'}${resourceUri}`, IXT_API_URL);

    const rawResponse = await fetch(url.toString(), getFetchMutationOptions(method, request, headers));
    /**
     * Note: To consume the rawResponse object multiple times, you must clone it to create
     * independent copies of the underlying readable stream. This ensures that you can read
     * the response content repeatedly without it being consumed after the first read.
     * reference: https://stackoverflow.com/questions/53511974/javascript-fetch-failed-to-execute-json-on-response-body-stream-is-locked
     */
    const response: TResponseData = await rawResponse.clone().json();

    if (rawResponse.ok) {
      return response;
    }

    const defaultMessage = `${rawResponse.status}: ${rawResponse.statusText}`;

    // TODO: refactor to throw `ApiAuthError` instead
    /* 401 and 403 API error should be `ApiResponseClientError` */
    if (hasAuthError(rawResponse, response)) {
      throw new ApiResponseClientError(rawResponse, rawResponse.statusText);
    }

    /**
     * 4xx API error should have status code to display error so we can use `ApiResponseClientError`
     * 5xx API error just throw `ApiResponseServerError` that contains the rawResponse (no status code expected)
     */
    if (hasStatusCode(response)) {
      throw new ApiResponseClientError(response, 'message' in response ? response.message : defaultMessage);
    } else if (isClientError(rawResponse)) {
      throw new ApiResponseClientError(rawResponse, rawResponse.statusText);
    } else {
      throw new ApiResponseServerError(response, defaultMessage);
    }
  } catch (error) {
    if (error instanceof ApiResponseClientError && Number(error.code) === ACCESS_TOKEN_ERROR) {
      getStore()?.dispatch(handleInvalidAccessToken());
    }
    throw error;
  }
};

interface IxtApiMutationFnParams<TRequest = MutationRequestPayload> {
  resourceUrl: string;
  method: MutationType;
  version?: 'v1' | 'internal';
  request?: TRequest;
  headers?: Record<string, string>;
}

export const ixtApiMutationFn = async <TRequest = MutationRequestPayload, TResponseData = void, TError = unknown>({
  resourceUrl,
  method,
  version = 'v1',
  request,
  headers,
}: IxtApiMutationFnParams<TRequest>) => {
  const url = new URL(`${resourceUrl.startsWith('/') ? `/${version}` : `/${version}/`}${resourceUrl}`, IXT_API_URL);

  const response = await fetch(url.toString(), getFetchMutationOptions(method, request, headers));
  const data = await response.json();

  if (response.ok) {
    return data as TResponseData;
  }
  // eslint-disable-next-line @typescript-eslint/no-throw-literal
  throw data as TError;
};

interface IDefaultDownloadMutationFn {
  url: string;
  openNewTab?: boolean;
  fileName: string;
}
export const defaultDownloadMutationFn = async ({ url, openNewTab = false, fileName }: IDefaultDownloadMutationFn) => {
  try {
    const blob = await defaultDownloadFn(url);

    if (openNewTab) {
      window.open(URL.createObjectURL(blob));
    } else {
      downloadBlob(blob, fileName);
    }
  } catch (error) {
    throw error;
  }
};
