import { FC, memo } from 'react';
import styled from 'styled-components';
import { TFunction } from 'next-i18next';

import AlertModal from '@/components/modal/AlertModal';
import ZIndices from '@/constants/zIndices';
import { CONTAINED_BUTTON_MODE } from '@/patterns/button/ContainedButton';

const Modal = styled(AlertModal)`
  z-index: ${ZIndices.Alert};
`;

interface Props {
  t: TFunction;
  show: boolean;
  onClose: () => void;
}
const SessionTimeoutAlertModal: FC<Props> = ({ t, show, onClose }) => (
  <Modal
    t={t}
    id="modal-session-timeout-alert"
    show={show}
    i18nKeyTitle="general.session_timeout_popup.title"
    i18nKeyDescription="general.session_timeout_popup.text_description"
    i18nKeyCloseButtonText="general.action_login"
    onClose={onClose}
    buttonMode={CONTAINED_BUTTON_MODE.PRIMARY}
  />
);

export default memo(SessionTimeoutAlertModal);
