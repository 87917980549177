import { createAction } from '@reduxjs/toolkit';

import { IncrementalId } from '@/interfaces/id';

import { INotification } from './interfaces';

export const setNotificationIsLoading = createAction<boolean>('app/notification/SET_NOTIFICATION_IS_LOADING');

export const getNotificationList = createAction('app/notification/GET_NOTIFICATION_LIST');
export const getNotificationListError = createAction<Error>('app/notification/GET_NOTIFICATION_LIST_ERROR');
export const receivedNotificationList = createAction<INotification[]>('app/notification/RECEIVED_NOTIFICATION_LIST');

export const readAllNotifications = createAction('app/notification/READ_ALL_NOTIFICATIONS');
export const readAllNotificationsError = createAction<Error>('app/notification/READ_ALL_NOTIFICATIONS_ERROR');
export const readAllNotificationsSuccess = createAction('app/notification/READ_ALL_NOTIFICATIONS_SUCCESS');

export const readSpecificNotification = createAction<IncrementalId>('app/notification/READ_SPECIFIC_NOTIFICATION');
export const readSpecificNotificationError = createAction<Error>('app/notification/READ_SPECIFIC_NOTIFICATION_ERROR');
export const readSpecificNotificationSuccess = createAction<IncrementalId>(
  'app/notification/READ_SPECIFIC_NOTIFICATION_SUCCESS'
);

export const pollingCheckUnreadNotifications = createAction('app/notification/POLLING_CHECK_UNREAD_NOTIFICATIONS');

export const checkUnreadNotificationExisting = createAction('app/notification/CHECK_UNREAD_NOTIFICATION_EXISTING');
export const checkUnreadNotificationExistingError = createAction<Error>(
  'app/notification/CHECK_UNREAD_NOTIFICATION_EXISTING_ERROR'
);
export const checkUnreadNotificationExistingSuccess = createAction<boolean>(
  'app/notification/CHECK_UNREAD_NOTIFICATION_EXISTING_SUCCESS'
);
