import { Epic, ofType } from 'redux-observable';
import { EMPTY, interval, of } from 'rxjs';
import { catchError, delay, filter, map, mapTo, switchMap, takeUntil, tap, throttleTime } from 'rxjs/operators';

import { ALERT_TYPE } from '@/constants/alert';
import { apiGetMe, apiUpdatePassword } from '@/services/OneDegree/auth';
import { apiGetActiveAuthentications } from '@/services/OneDegree/authentication';
import { setSentryUserInfo } from '@/services/Sentry';

import {
  getCallingCodeList,
  getCountryList,
  getCurrencyList,
  getLocaleList,
  getMarketList,
  getPlatformList,
  getTimezoneList,
  pushAlert,
} from '../MiscProvider/actions';
import {
  cancelExtendAccessToken,
  extendAccessToken,
  getAccessToken,
  getAccessTokenError,
  getActiveAuthentications,
  getActiveAuthenticationsError,
  getMe,
  getMeError,
  handleInvalidAccessToken,
  handleSessionTimeout,
  receivedAccessToken,
  receivedActiveAuthentications,
  receivedMe,
  renewExtendAccessToken,
  sendResetPasswordEmail,
  sendResetPasswordEmailError,
  sendResetPasswordEmailSucceed,
  updatePassword,
  updatePasswordError,
  updatePasswordSucceed,
} from './actions';

// Use throttleTime for now. Will be using something more suitable later.
const handleInvalidAccessTokenEpic: Epic = action$ =>
  action$.pipe(ofType(handleInvalidAccessToken.type), throttleTime(300), mapTo(getAccessTokenError()));

const extendAccessTokenEpic: Epic = action$ =>
  action$.pipe(
    filter(extendAccessToken.match),
    switchMap(({ payload: { expiration_time } }) => {
      const expirationDate = new Date(expiration_time);
      const delayMs = expirationDate.getTime() - Date.now() - 60 * 1000; /* token expired a minute ago */
      return interval(3000).pipe(
        mapTo(getAccessToken()),
        delay(delayMs),
        takeUntil(action$.pipe(ofType(cancelExtendAccessToken.type, renewExtendAccessToken.type)))
      );
    })
  );

const receivedAccessTokenEpic: Epic = action$ =>
  action$.pipe(
    ofType(receivedAccessToken.type),
    switchMap(() =>
      of(
        getMe(),
        getCallingCodeList(),
        getCountryList(),
        getCurrencyList(),
        getMarketList(),
        getTimezoneList(),
        getPlatformList(),
        getLocaleList()
      )
    )
  );

const sendResetPasswordEpic: Epic = action$ =>
  action$.pipe(
    filter(sendResetPasswordEmail.match),
    switchMap(({ meta: { callbacks } }) => {
      const { success, fail, complete } = callbacks;
      return EMPTY.pipe(
        map(() => {
          success?.();
          complete?.();
          return sendResetPasswordEmailSucceed();
        }),
        catchError(error => {
          fail?.(error);
          complete?.(undefined, error);
          return of(sendResetPasswordEmailError(), handleSessionTimeout(error));
        })
      );
    })
  );

const userUpdatePasswordEpic: Epic = action$ =>
  action$.pipe(
    filter(updatePassword.match),
    switchMap(({ payload, meta: { callbacks } }) => {
      const { fail } = callbacks;
      return apiUpdatePassword(payload).pipe(
        map(() => {
          pushAlert({
            type: ALERT_TYPE.SUCCESS,
            text: 'system.update_password.text_snackbar_password_updated_successful',
          });
          return updatePasswordSucceed();
        }),
        catchError(error => {
          fail?.(error);
          return of(updatePasswordError(), handleSessionTimeout(error));
        })
      );
    })
  );

const getMeEpic: Epic = action$ =>
  action$.pipe(
    ofType(getMe.type),
    switchMap(() =>
      apiGetMe().pipe(
        map(({ data }) => receivedMe(data)),
        catchError(error => of(getMeError(error), handleSessionTimeout(error)))
      )
    )
  );

const receivedMeEpic: Epic = action$ =>
  action$.pipe(
    filter(receivedMe.match),
    tap(({ payload: { id, email_address, full_name, roles } }) =>
      setSentryUserInfo({ id, email: email_address, name: full_name, roles })
    ),
    switchMap(() => of())
  );

const getActiveAuthenticationsEpic: Epic = action$ =>
  action$.pipe(
    ofType(getActiveAuthentications.type),
    switchMap(() =>
      apiGetActiveAuthentications().pipe(
        map(({ data }) => receivedActiveAuthentications(data)),
        catchError(error => of(getActiveAuthenticationsError(), handleSessionTimeout(error)))
      )
    )
  );

export default [
  userUpdatePasswordEpic,
  sendResetPasswordEpic,
  extendAccessTokenEpic,
  receivedAccessTokenEpic,
  getMeEpic,
  receivedMeEpic,
  handleInvalidAccessTokenEpic,
  getActiveAuthenticationsEpic,
];
