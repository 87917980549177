import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { useAuth } from './useAuth';

export const useAuthRoute = () => {
  const [hasSignedIn, setHasSignedIn] = useState(false);

  const { asPath } = useRouter();
  const { signIn, authRequired } = useAuth();

  useEffect(() => {
    if (!authRequired) return;
    if (!hasSignedIn) signIn(true);
    else signIn(false);
    setHasSignedIn(true);
  }, [hasSignedIn, asPath, signIn, authRequired]);
};
