import { createSelector } from 'reselect';

import { REMARK_ATTACHMENT_STATE } from '@/constants/remark';
import { Uuid } from '@/interfaces/id';
import { RootState } from '@/interfaces/redux';

const selectRemark = (state: RootState) => state.remark;

export const uploadTaskMapSelector = createSelector(selectRemark, content => content.uploadTaskMap);

const makeUploadTaskSelector = (id: Uuid) => createSelector(uploadTaskMapSelector, content => content[id]);

export const uploadTaskDataSelector = (id: Uuid) =>
  createSelector(makeUploadTaskSelector(id), content => content?.data);

export const isUploadTaskPendingSelector = (id: Uuid) =>
  createSelector(makeUploadTaskSelector(id), content => content?.state === REMARK_ATTACHMENT_STATE.PENDING);
