import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useAtom } from 'jotai';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';

import {
  accessTokenAtom,
  internalUserIdAtom,
  isLoggedInAtom,
  isLoggingOutAtom,
  permissionsAtom,
} from '@/atom/authAtom';
import {
  COOKIE_ACCESS_TOKEN,
  QUERY_KEY_ACCESS_TOKEN,
  QUERY_KEY_REDIRECT_TO_SIGN_IN,
  QUERY_KEY_REDIRECT_URI,
} from '@/constants/auth';
import { MODULE, PERMISSION, SERVICE } from '@/constants/permission';
import { ignoreAuthPathnames } from '@/constants/urls';
import useWatchVisibilityChange from '@/hooks/useWatchVisibilityChange';
import { IApiAuthenticateResponse } from '@/interfaces/auth';
import useAuthServiceQuery from '@/query-utils/useAuthServiceQuery';
import { receivedAccessToken, setIsSessionTimeoutTrue } from '@/redux/AuthProvider/actions';
import { AUTH_WEB_URL } from '@/services/OneDegree/constants';

export const AUTH_WEB_ENDPOINT_AUTHENTICATE = '/api/authenticate';

const redirectToAuthAppSignOut = (accessToken = '', redirectToSignIn = true) => {
  const nextUrl = new URL('/logout', AUTH_WEB_URL);
  const currentUrl = new URL(window.location.href);

  nextUrl.searchParams.set(QUERY_KEY_REDIRECT_URI, currentUrl.toString());
  nextUrl.searchParams.set(QUERY_KEY_ACCESS_TOKEN, accessToken);
  if (redirectToSignIn) {
    nextUrl.searchParams.append(QUERY_KEY_REDIRECT_TO_SIGN_IN, 'true');
  }
  window.location.replace(nextUrl.toString());
};

export const redirectToAuthAppSignIn = () => {
  const nextUrl = new URL('/login', AUTH_WEB_URL);
  const currentUrl = new URL(window.location.href);
  // query should not contain token when redirect to ixt-auth
  currentUrl.searchParams.delete(QUERY_KEY_ACCESS_TOKEN);
  nextUrl.searchParams.set(QUERY_KEY_REDIRECT_URI, currentUrl.toString());
  window.location.replace(nextUrl.toString());
};

export const useAuth = () => {
  const [accessToken, setAccessToken] = useAtom(accessTokenAtom);
  const [isLoggedIn, setIsLoggedIn] = useAtom(isLoggedInAtom);
  const [isLoggingOut, setIsLoggingOut] = useAtom(isLoggingOutAtom);
  const [internalUserId, setInternalUserId] = useAtom(internalUserIdAtom);
  const [permissions, setPermissions] = useAtom(permissionsAtom);

  const { pathname } = useRouter();

  const dispatch = useDispatch();
  const handleSessionTimeout = useCallback(() => {
    dispatch(setIsSessionTimeoutTrue());
  }, [dispatch]);

  const signIn = useCallback(
    async (isInitialSignIn: boolean = false) => {
      const currentUrl = new URL(window.location.href);
      let token = currentUrl.searchParams.get(QUERY_KEY_ACCESS_TOKEN);
      if (token) {
        Cookies.set(COOKIE_ACCESS_TOKEN, token, { expires: 1 / 48 });
      } else {
        token = Cookies.get(COOKIE_ACCESS_TOKEN) ?? null;

        if (!token) {
          setIsLoggedIn(false);
          if (!isLoggingOut) redirectToAuthAppSignIn();
          return;
        }
      }

      // should add no-cache to headers to prevent browser caching when user go back to the previous page
      const authHeaders = new Headers();
      authHeaders.append('pragma', 'no-cache');
      authHeaders.append('cache-control', 'no-cache');

      const authInit = {
        headers: authHeaders,
      };

      const apiUrl = new URL(AUTH_WEB_ENDPOINT_AUTHENTICATE, AUTH_WEB_URL);
      apiUrl.searchParams.set('token', token);
      const response = await fetch(apiUrl.toString(), authInit);
      if (!response.ok) {
        if (isInitialSignIn) {
          setIsLoggedIn(false);
          redirectToAuthAppSignIn();
        } else {
          handleSessionTimeout();
        }
        return;
      }

      const { internal_user_id, permission_sets }: IApiAuthenticateResponse = await response.json();

      if (!internal_user_id || !permission_sets) {
        if (isInitialSignIn) {
          setIsLoggedIn(false);
          redirectToAuthAppSignIn();
        } else {
          handleSessionTimeout();
        }
        return;
      }

      setInternalUserId(internal_user_id);
      setPermissions(permission_sets);
      setIsLoggedIn(true);

      if (isInitialSignIn) {
        dispatch(receivedAccessToken());
      }
    },
    [setInternalUserId, setPermissions, setIsLoggedIn, dispatch, isLoggingOut, handleSessionTimeout]
  );

  const signOut = useCallback(() => {
    const token = Cookies.get(COOKIE_ACCESS_TOKEN);
    if (token) Cookies.remove(COOKIE_ACCESS_TOKEN);

    setAccessToken('');
    setIsLoggingOut(true);
    setIsLoggedIn(false);
    redirectToAuthAppSignOut(token);
  }, [setAccessToken, setIsLoggedIn, setIsLoggingOut]);

  const keys = useMemo(() => permissions?.map(({ key, module }) => `${module}.${key}`) ?? [], [permissions]);

  const hasPermissions = useCallback(
    (permissionRequired: PERMISSION | PERMISSION[]) => {
      if (keys.length === 0) return false;
      const targets = typeof permissionRequired === 'string' ? [permissionRequired] : permissionRequired;
      return targets.every(permission => keys.includes(permission));
    },
    [keys]
  );

  const hasAnyOfPermissions = useCallback(
    (permissionRequired: PERMISSION[]) => permissionRequired.some(permission => keys.includes(permission) ?? false),
    [keys]
  );

  const hasAnyOfModulePermission = useCallback(
    (requiredModules: MODULE[]) => requiredModules.some(module => permissions.some(p => p.module === module)),
    [permissions]
  );

  const hasAnyOfServicePermission = useCallback(
    (requiredServices: SERVICE[]) => requiredServices.some(service => permissions.some(p => p.service === service)),
    [permissions]
  );

  return {
    authRequired: !ignoreAuthPathnames.includes(pathname),
    accessToken,
    setAccessToken,
    signIn,
    signOut,
    isLoggedIn,
    setIsLoggedIn,
    isLoggingOut,
    setIsLoggingOut,
    internalUserId,
    permissions,
    hasPermissions,
    hasAnyOfPermissions,
    hasAnyOfModulePermission,
    hasAnyOfServicePermission,
  };
};

export const useReAuth = () => {
  const { accessToken } = useAuth();
  const { refetch } = useAuthServiceQuery<IApiAuthenticateResponse>(
    ['api', 'authenticate'],
    { enabled: false },
    { token: accessToken ?? '' }
  );
  return useCallback(async () => {
    await refetch({ stale: true });
  }, [refetch]);
};

export const useAuthWhenPageIsVisible = () => {
  const { setAccessToken, authRequired } = useAuth();
  const reAuth = useReAuth();

  const onVisible = useCallback(async () => {
    if (authRequired) {
      setAccessToken(Cookies.get(COOKIE_ACCESS_TOKEN) ?? '');
      await reAuth();
    }
  }, [authRequired, reAuth, setAccessToken]);

  useWatchVisibilityChange({ onVisible });
};
