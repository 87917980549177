import styled, { keyframes } from 'styled-components';

const Rotate = keyframes`
  from { transform: rotate(0); }
  to { transform: rotate(360deg); }
`;

const RotateCircleLoading = styled.div<{ color: string }>`
  width: 1rem;
  height: 1rem;
  border: 0.125rem solid ${props => props.color};
  border-right-color: transparent;
  border-radius: 50%;
  animation: ${Rotate} 1s linear infinite;
`;

export default RotateCircleLoading;
