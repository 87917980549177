import _get from 'lodash/get';
import _orderBy from 'lodash/orderBy';

/**
 * Get the sorted array by a given field path(s)
 */
function getSortedDataByField<T>(data: T[], fieldPath: keyof T | string[], ascending: boolean): T[] {
  /**
   * XXX: lodash-webpack-plugin will shake out all the iteratee shorthands,
   *      always use a function to prevent heavier bundle sizes
   * @see https://github.com/lodash/lodash-webpack-plugin/issues/128
   */
  return _orderBy<T>(data, [field => _get(field, fieldPath)], [ascending ? 'asc' : 'desc']);
}

export default getSortedDataByField;
