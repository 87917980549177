import { createAction } from '@reduxjs/toolkit';

import { IdType, Uuid } from '@/interfaces/id';

// common
export const uploadRemarkAttachment = createAction<{ id: Uuid; data: File }>('app/remark/UPLOAD_REMARK_ATTACHMENT');
export const uploadRemarkAttachmentError = createAction<{ id: Uuid; error: Error }>(
  'app/remark/UPLOAD_REMARK_ATTACHMENT_ERROR'
);
export const uploadRemarkAttachmentSuccess = createAction<{ id: Uuid; data: { id: IdType } }>(
  'app/remark/UPLOAD_REMARK_ATTACHMENT_SUCCESS'
);
export const startUploadRemarkAttachment = createAction<{ id: Uuid }>('app/remark/START_UPLOAD_REMARK_ATTACHMENT');
export const retryUploadRemarkAttachment = createAction<{ id: Uuid }>('app/remark/RETRY_UPLOAD_REMARK_ATTACHMENT');
export const deleteUploadRemarkAttachment = createAction<{ id: Uuid }>('app/remark/DELETE_UPLOAD_REMARK_ATTACHMENT');
export const cancelAllUploadRemarkAttachment = createAction('app/remark/CANCEL_ALL_UPLOAD_REMARK_ATTACHMENT');
