const dummyEnd = 0;

/**
 * Convert fields to graphql-like syntax query
 *
 * @param fields array of field string, e.g. ['id', 'name', 'plans.id', 'plans.benefits.id', 'plans.benefits.name', 'type']
 * @return query string, e.g. '{id,name,plans{id,benefits{id,name}},type}'
 */
function convertFieldsToQuery<T extends string>(fields: T[] | readonly T[]): string {
  /* convert fields to json object */
  const result = {};
  fields.forEach(field => {
    let obj = result;
    const keys = field.split('.');

    if (keys.length === 0) {
      return;
    }

    keys.slice(0, -1).forEach(key => {
      if (key === 'undefined') {
        throw new Error(`field contains undefined key: ${field}`);
      }

      if (!(key in obj)) {
        obj[key] = {};
      } else if (typeof obj[key] !== 'object') {
        throw new Error(`convert failed on field: ${field}`);
      }
      obj = obj[key];
    });

    obj[keys[keys.length - 1]] = dummyEnd;
  });

  /* remove comma, colon and dummy from json string to generate graphql-like syntax */
  const regex = new RegExp(`"|:${dummyEnd}?`, 'g');
  return JSON.stringify(result).replace(regex, '');
}

export default convertFieldsToQuery;
