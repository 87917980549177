export const IXT_API_ENDPOINT_ACTIVITY_LOG = '/activity-logs';
export const IXT_API_ENDPOINT_APPLICATION = '/applications';
export const IXT_API_ENDPOINT_ATTACHMENT = '/attachments';
export const IXT_API_ENDPOINT_ATTRIBUTE_TEMPLATE = '/attribute-templates';
export const IXT_API_ENDPOINT_CONSTRAINT_TEMPLATE = '/constraint-templates';
export const IXT_API_ENDPOINT_POLICY = '/policies';
export const IXT_API_ENDPOINT_POLICY_ENDORSEMENT = '/policy-endorsements';
export const IXT_API_ENDPOINT_ENDORSEMENT_REVIEW_REQUEST = '/endorsement-review-requests';
export const IXT_API_ENDPOINT_REFERENCE_TABLE = '/reference-tables';

export const IXT_API_ENDPOINT_FORM_APPLICATION = '/form-applications';

export const IXT_API_ENDPOINT_FORM = '/forms';

export const IXT_API_ENDPOINT_RULE = 'rules';
