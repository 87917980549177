import { createAction } from '@reduxjs/toolkit';

import { IAttachment } from '@/interfaces/attachment';
import { IdType } from '@/interfaces/id';
import { createCallbacksAction } from '@/utils/redux';

const ACTION_PREFIX = 'app/attachment';

export const uploadAttachment = createCallbacksAction<File, { id: IdType }>(`${ACTION_PREFIX}/UPLOAD_ATTACHMENT`);

export const cancelUploadAttachment = createAction(`${ACTION_PREFIX}/CANCEL_UPLOAD_ATTACHMENT`);

export const deleteAttachment = createCallbacksAction<IAttachment>(`${ACTION_PREFIX}/DELETE_ATTACHMENT`);
