const Typography = {
  H2_HEADING: 'typography-h2-heading',
  H4_HEADING: 'typography-h4-heading',
  H6_HEADING: 'typography-h6-heading',
  SUBTITLE_L: 'typography-subtitle-l',
  SUBTITLE_L_M: 'typography-subtitle-l-m',
  SUBTITLE_M: 'typography-subtitle-m',
  SUBTITLE_M_M: 'typography-subtitle-m-m',
  BODY_M: 'typography-body-m',
  BODY_M_M: 'typography-body-m-m',
  BODY_S: 'typography-body-s',
  BODY_S_M: 'typography-body-s-m',
  BUTTON_M: 'typography-button-m',
  BUTTON_S: 'typography-button-s',
  CAPTION: 'typography-caption',
  OVERLINE: 'typography-overline',
};

export default Typography;
