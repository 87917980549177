import { ButtonHTMLAttributes, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import classnames from 'classnames';

import RotateCircleLoading from '@/components/loading/RotateCircleLoading';
import Colors from '@/constants/colors';
import Typography from '@/constants/typography';

import containedButtonCssMixin from './mixin/containedButtonCssMixin';

export enum CONTAINED_BUTTON_MODE {
  PRIMARY = 'primary',
  ERROR = 'error',
}

const ColorMap = {
  [CONTAINED_BUTTON_MODE.PRIMARY]: {
    loadingBG: Colors.PRIMARY_500,
    defaultBG: Colors.PRIMARY_500,
    hoverBG: Colors.PRIMARY_300,
    activeBG: Colors.PRIMARY_700,
  },
  [CONTAINED_BUTTON_MODE.ERROR]: {
    loadingBG: Colors.ERROR_600,
    defaultBG: Colors.ERROR_600,
    hoverBG: Colors.ERROR_400,
    activeBG: Colors.ERROR_800,
  },
};

interface BtnProps {
  $mode: CONTAINED_BUTTON_MODE;
  $loading?: boolean;
  disabled?: boolean;
}

const Btn = styled.button<BtnProps>`
  ${containedButtonCssMixin};

  ${props => {
    const bgColors = ColorMap[props.$mode];

    if (props.$loading) {
      return css`
        cursor: default;
        background-color: ${bgColors.loadingBG};
      `;
    }
    if (!props.disabled) {
      return css`
        color: ${Colors.WHITE_100};
        background-color: ${bgColors.defaultBG};

        &:hover,
        &:focus {
          background-color: ${bgColors.hoverBG};
        }

        &:active {
          background-color: ${bgColors.activeBG};
        }
      `;
    }
    return '';
  }}
`;

// Export to <ContainedButtonWithDropdown /> component
export interface ContainedButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  id: string;
  mode?: CONTAINED_BUTTON_MODE;
  disabled?: boolean;
  loading?: boolean;
}

const ContainedButton = forwardRef<HTMLButtonElement, ContainedButtonProps>(
  ({ id, className, mode = CONTAINED_BUTTON_MODE.PRIMARY, disabled, loading, children, ...props }, ref) => (
    <Btn
      id={id}
      ref={ref}
      className={classnames(className, Typography.BUTTON_S)}
      $mode={mode}
      $loading={loading}
      disabled={disabled || loading}
      {...props}
    >
      {loading ? <RotateCircleLoading color={Colors.WHITE_100} /> : children}
    </Btn>
  )
);

export default ContainedButton;
