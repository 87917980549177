import { DefaultOptions, QueryKey } from '@tanstack/react-query';
import { merge } from 'lodash';
import { JsonPrimitive } from 'type-fest';

import { PER_REQUEST_ITEM_LIMIT } from '@/constants/common';

import { defaultQueryFn, ixtApiQueryFn } from './fetchers';
import {
  InternalApiResourceQueryKey,
  InternalApiResourcesQueryKey,
  IxtApiQueryKey,
  StringifyQueryKey,
} from './interfaces';
import { getInternalApiQueryUrl, getQueryUrl, splitChunksByRoot } from './utils';

const ensureInternalApiResourceQueryKey = (queryKey: QueryKey): queryKey is InternalApiResourceQueryKey => {
  return !!(
    Array.isArray(queryKey) &&
    typeof queryKey[0] === 'string' &&
    typeof queryKey[1] === 'number' &&
    Array.isArray(queryKey[2]) &&
    (typeof queryKey[3] === 'boolean' ? true : typeof queryKey[3] === 'undefined')
  );
};

const ensureInternalApiResourcesQueryKey = (queryKey: QueryKey): queryKey is InternalApiResourcesQueryKey => {
  return !!(
    Array.isArray(queryKey) &&
    typeof queryKey[0] === 'string' &&
    queryKey[1] === undefined &&
    Array.isArray(queryKey[2]) &&
    typeof queryKey[3] === 'object'
  );
};

const ensureStringifyQueryKey = (queryKey: QueryKey): queryKey is StringifyQueryKey => {
  return (
    Array.isArray(queryKey) &&
    queryKey.length > 0 &&
    queryKey.every(val => typeof val === 'string' || typeof val === 'number')
  );
};

const ensureIxtApiQueryKey = <TParam extends Record<string, JsonPrimitive>>(
  queryKey: QueryKey
): queryKey is IxtApiQueryKey<TParam> => {
  return !!(Array.isArray(queryKey) && ensureStringifyQueryKey(queryKey[0]));
};

const queryClientConfig: DefaultOptions = {
  queries: {
    // staleTime: 0,
    // cacheTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    queryFn: async ({ queryKey, pageParam: offset = 0, meta }) => {
      // add the ability to pass custom request headers using the query `meta`
      const requestHeaders = meta && 'headers' in meta ? (meta.headers as Record<string, string>) : undefined;

      if (ensureIxtApiQueryKey(queryKey)) {
        return ixtApiQueryFn({
          resourceUrl: queryKey[0].join('/'),
          query: queryKey[1],
          headers: requestHeaders,
        });
      }

      if (ensureInternalApiResourceQueryKey(queryKey)) {
        const [resourceName, resourceId, fields, splitChunks = false] = queryKey;
        if (splitChunks) {
          const responses = await Promise.all(
            splitChunksByRoot(fields).map(fieldChunk =>
              defaultQueryFn(getInternalApiQueryUrl([resourceName, resourceId!, fieldChunk]), requestHeaders)
            )
          );
          return responses.reduce((acc, response) => merge(acc, response), Object.create(null));
        }
        return defaultQueryFn(getInternalApiQueryUrl([resourceName, resourceId, fields]), requestHeaders);
      }
      if (ensureInternalApiResourcesQueryKey(queryKey)) {
        const [resourceName, , fields, request] = queryKey;
        return defaultQueryFn(
          getInternalApiQueryUrl([
            resourceName,
            undefined,
            fields,
            {
              ...request,
              limit: PER_REQUEST_ITEM_LIMIT,
              offset,
            },
          ]),
          requestHeaders
        );
      }
      if (ensureStringifyQueryKey(queryKey)) {
        const url = getQueryUrl(queryKey);
        return defaultQueryFn(url, requestHeaders);
      }
      throw new Error(
        'To use default queryFn, please provide queryKey that follows the interface of `InternalApiQueryKey` or `StringifyQueryKey`; otherwise you should provide the queryFn explicitly'
      );
    },
  },
};

export default queryClientConfig;
