import { createReducer } from '@reduxjs/toolkit';

import {
  checkUnreadNotificationExistingSuccess,
  getNotificationList,
  getNotificationListError,
  readSpecificNotificationSuccess,
  receivedNotificationList,
  setNotificationIsLoading,
} from './actions';
import { INotificationReducerState } from './interfaces';

const initialState: INotificationReducerState = {
  data: [],
  isLoading: false,
  isUnreadExisting: false,
};

const reducer = createReducer<INotificationReducerState>(initialState, builder => {
  builder
    .addCase(getNotificationList, state => {
      delete state.error;
    })
    .addCase(getNotificationListError, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    })
    .addCase(receivedNotificationList, (state, { payload }) => {
      const newNotifications = payload;
      state.data = newNotifications;
      state.isLoading = false;
      // TODO: no need to count unread notification when if infinite scroll mode
      state.isUnreadExisting = newNotifications.some(item => !item.isRead);
    })
    .addCase(readSpecificNotificationSuccess, (state, { payload }) => {
      const readNotificationId = payload;
      let isUnreadExisting = false;
      state.data = state.data.map(item => {
        if (item.id === readNotificationId) {
          return {
            ...item,
            isRead: true,
          };
        }
        isUnreadExisting = isUnreadExisting || !item.isRead;
        return item;
      });
      // TODO: no need to count unread notification when if infinite scroll mode
      state.isUnreadExisting = isUnreadExisting;
    })
    .addCase(checkUnreadNotificationExistingSuccess, (state, { payload }) => {
      state.isUnreadExisting = payload;
    })
    .addCase(setNotificationIsLoading, (state, { payload }) => {
      state.isLoading = payload;
    });
});

export default reducer;
