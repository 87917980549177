import 'focus-visible/dist/focus-visible';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import { useDispatch } from 'react-redux';
import { initPassiveOptionSupport } from '@ixt/utilities';
import { loader } from '@monaco-editor/react';
import { Hydrate, MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { StatusCodes } from 'http-status-codes';
import { DevTools as JotaiDevTools } from 'jotai-devtools';
import type { AppProps } from 'next/app';
import { useKeys } from 'rooks';

import SharedLayout from '@/components/SharedLayout';
import { ALERT_TYPE } from '@/constants/alert';
import { ApiResponseClientError } from '@/constants/errors';
import { AuthContext } from '@/decorators/withPermission';
import { useAuth } from '@/effects/useAuth';
import { useAuthRoute } from '@/effects/useAuthRoute';
import PageClientError from '@/modules/general/PageClientError';
import ModuleLoader from '@/modules/ModuleLoader';
import { setIsSessionTimeoutTrue } from '@/redux/AuthProvider/actions';
import { pushAlert } from '@/redux/MiscProvider/actions';
import wrapper from '@/redux/store';
import { initDeviceInfo } from '@/services/Device';
import { appWithTranslation } from '@/services/I18nNext';
import queryClientConfig from '@/services/ReactQuery/config';
import { captureSentryException, initSentry } from '@/services/Sentry';

loader.config({ paths: { vs: '/static/monaco-editor/min/vs' } });

dayjs.extend(customParseFormat);
dayjs.extend(timezone);
dayjs.extend(utc);

const IXTApp = ({ Component, pageProps: { dehydratedState, ...pageProps } }: AppProps) => {
  useAuthRoute();

  const {
    isLoggedIn,
    isLoggingOut,
    permissions,
    hasPermissions,
    hasAnyOfPermissions,
    hasAnyOfModulePermission,
    hasAnyOfServicePermission,
    internalUserId,
    authRequired,
  } = useAuth();

  const dispatch = useDispatch();
  const handleSessionTimeout = useCallback(() => {
    dispatch(setIsSessionTimeoutTrue());
  }, [dispatch]);

  const queryErrorHandler = useCallback(
    (error: unknown) => {
      // TODO: refactor to check for ApiAuthError instead
      if (error instanceof ApiResponseClientError) {
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleSessionTimeout();
        } else if (error.status === StatusCodes.FORBIDDEN) {
          dispatch(
            pushAlert({
              type: ALERT_TYPE.FAIL,
              text: 'general.forbidden_text',
            })
          );
        }
      }
    },
    [dispatch, handleSessionTimeout]
  );

  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: queryClientConfig,
        queryCache: new QueryCache({
          onError: queryErrorHandler,
        }),
        mutationCache: new MutationCache({
          onError: queryErrorHandler,
        }),
      }),
    [queryErrorHandler]
  );

  const [shouldRenderDevTools, setShouldRenderDevTools] = useState(false);
  useKeys(['ControlLeft', 'KeyR', 'KeyQ'], () =>
    setShouldRenderDevTools(prev => process.env.NODE_ENV === 'development' && !prev)
  );

  useEffect(() => {
    initPassiveOptionSupport();
    initDeviceInfo(window.navigator.userAgent);
    initSentry();

    /* this polyfill only works on client-side */
    (async () => {
      await import('broadcastchannel-polyfill');
    })();
  }, []);

  useEffect(() => {
    const userPrefersDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    document.body.setAttribute('theme-value', userPrefersDark ? 'dark' : 'light');
  }, []);

  const authContextValue = useMemo(
    () => ({
      authRequired,
      internalUserId,
      isLoggedIn,
      isLoggingOut,
      permissions,
      hasPermissions,
      hasAnyOfPermissions,
      hasAnyOfModulePermission,
      hasAnyOfServicePermission,
    }),
    [
      authRequired,
      internalUserId,
      isLoggedIn,
      isLoggingOut,
      permissions,
      hasPermissions,
      hasAnyOfPermissions,
      hasAnyOfModulePermission,
      hasAnyOfServicePermission,
    ]
  );

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={dehydratedState}>
        <ModuleLoader>
          <AuthContext.Provider value={authContextValue}>
            <SharedLayout namespaces={pageProps.namespacesRequired}>
              <Component {...pageProps} />
            </SharedLayout>
          </AuthContext.Provider>
        </ModuleLoader>
      </Hydrate>
      {shouldRenderDevTools && (
        <>
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          <JotaiDevTools />
        </>
      )}
    </QueryClientProvider>
  );
};

export default wrapper.withRedux(
  appWithTranslation(
    withErrorBoundary(IXTApp, {
      fallback: <PageClientError />,
      onError(error, extra) {
        captureSentryException({ error, extra });
      },
    })
  )
);
