import SCHEMA_LDAP_AUTHENTICATION from '@/constants/schema/LDAPAuthentication';
import {
  IApiCreateResourceMethod,
  IApiDeleteResourceMethod,
  IApiGetResourcesMethod,
  IApiUpdateResourceMethod,
} from '@/interfaces/api';
import {
  ICreateLDAPAuthentication,
  IRetrieveActiveAuthenticationsResponse,
  IUpdateEmailAuthentication,
  IUpdateLDAPAuthentication,
} from '@/interfaces/od-api';

import { ixtApiDelete, ixtApiGet, ixtApiGetResources, ixtApiPatch, ixtApiPost } from './utils';

export const apiGetEmailAuthentications: IApiGetResourcesMethod = ({ offset, fields }) =>
  ixtApiGetResources({
    url: '/email-authentications',
    request: {
      offset,
      fields,
    },
  });

export const apiUpdateEmailAuthentication: IApiUpdateResourceMethod<IUpdateEmailAuthentication> = (id, request) =>
  ixtApiPatch({
    url: `/email-authentications/${id}`,
    request,
  });

export const apiGetLdapAuthentications: IApiGetResourcesMethod = ({
  offset,
  orderBy = SCHEMA_LDAP_AUTHENTICATION.CREATE_TIME,
  ascending = true,
  fields,
}) =>
  ixtApiGetResources({
    url: '/ldap-authentications',
    request: {
      offset,
      orderBy,
      ascending,
      fields,
    },
  });

export const apiCreateLdapAuthentication: IApiCreateResourceMethod<ICreateLDAPAuthentication> = data =>
  ixtApiPost({
    url: '/ldap-authentications',
    request: data,
  });

export const apiUpdateLdapAuthentication: IApiUpdateResourceMethod<IUpdateLDAPAuthentication> = (id, request) =>
  ixtApiPatch({
    url: `/ldap-authentications/${id}`,
    request,
  });

export const apiDeleteLdapAuthentication: IApiDeleteResourceMethod = id =>
  ixtApiDelete({
    url: `/ldap-authentications/${id}`,
  });

export const apiGetActiveAuthentications = () =>
  ixtApiGet<IRetrieveActiveAuthenticationsResponse['data']>({
    url: '/active-authentications',
  });
