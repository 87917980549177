import { createContext, FC, useContext, useEffect } from 'react';
import { useSetAtom } from 'jotai';
import Cookies from 'js-cookie';

import { accessTokenAtom } from '@/atom/authAtom';
import { COOKIE_ACCESS_TOKEN } from '@/constants/auth';
import { MODULE, PERMISSION, SERVICE } from '@/constants/permission';
import Urls from '@/constants/urls';
import { redirectToAuthAppSignIn } from '@/effects/useAuth';
import { IApiAuthenticateResponse } from '@/interfaces/auth';
import Router from '@/utils/nextRouter';

interface AuthContextValues {
  accessToken?: string;
  authRequired: boolean;
  internalUserId?: IApiAuthenticateResponse['internal_user_id'];
  permissions?: IApiAuthenticateResponse['permission_sets'];
  isLoggedIn: boolean;
  isLoggingOut: boolean;
  hasPermissions: (permissionRequired: PERMISSION | PERMISSION[]) => boolean;
  hasAnyOfPermissions: (permissionRequired: PERMISSION[]) => boolean;
  hasAnyOfModulePermission: (requiredModules: MODULE[]) => boolean;
  hasAnyOfServicePermission: (requiredServices: SERVICE[]) => boolean;
}

export const AuthContext = createContext<AuthContextValues>({
  accessToken: '',
  authRequired: true,
  internalUserId: 0,
  permissions: [],
  isLoggedIn: false,
  isLoggingOut: false,
  hasPermissions: () => false,
  hasAnyOfPermissions: () => false,
  hasAnyOfModulePermission: () => false,
  hasAnyOfServicePermission: () => false,
});

const withPermission = (permissionsRequired: PERMISSION[]) => (WrappedComponent: FC<any>) => (props: any) => {
  const setAccessToken = useSetAtom(accessTokenAtom);
  const { internalUserId, permissions, hasPermissions, isLoggingOut, isLoggedIn } = useContext(AuthContext);
  const accessToken = Cookies.get(COOKIE_ACCESS_TOKEN);

  useEffect(() => {
    if (typeof accessToken !== 'string') {
      if (!isLoggingOut) redirectToAuthAppSignIn();
    }
    setAccessToken(accessToken ?? '');
  }, [accessToken, isLoggingOut, setAccessToken]);

  if (!permissions || internalUserId == null || !isLoggedIn) return null;

  if (hasPermissions(permissionsRequired)) {
    return <WrappedComponent {...props} />;
  }

  Router.replaceRoute(Urls.Forbidden);
  return null;
};

export default withPermission;
