import SCHEMA_CALLING_CODE from '@/constants/schema/callingCode';
import SCHEMA_COUNTRY from '@/constants/schema/country';
import SCHEMA_CURRENCY from '@/constants/schema/currency';
import SCHEMA_LOCALE from '@/constants/schema/locale';
import SCHEMA_MARKET from '@/constants/schema/market';
import SCHEMA_PLATFORM from '@/constants/schema/platform';
import SCHEMA_TIMEZONE from '@/constants/schema/timezone';

export const FIELDS_CALLING_CODE_LIST = <const>[SCHEMA_CALLING_CODE.ID, SCHEMA_CALLING_CODE.CODE];

export const FIELDS_COUNTRY_LIST = <const>[SCHEMA_COUNTRY.ID, SCHEMA_COUNTRY.NAME];

export const FIELDS_CURRENCY_LIST = <const>[SCHEMA_CURRENCY.ID, SCHEMA_CURRENCY.NAME];

export const FIELDS_MARKET_LIST = <const>[SCHEMA_MARKET.ID, SCHEMA_MARKET.CODE, SCHEMA_MARKET.NAME];

export const FIELDS_LOCALE_LIST = <const>[SCHEMA_LOCALE.ID, SCHEMA_LOCALE.CODE];

export const FIELDS_TIMEZONE_LIST = <const>[SCHEMA_TIMEZONE.ID, SCHEMA_TIMEZONE.NAME, SCHEMA_TIMEZONE.OFFSET];

export const FIELDS_PLATFORM_LIST = <const>[
  SCHEMA_PLATFORM.ID,
  SCHEMA_PLATFORM.NAME,
  `${SCHEMA_PLATFORM.LOCALES}.${SCHEMA_LOCALE.ID}`,
  `${SCHEMA_PLATFORM.LOCALES}.${SCHEMA_LOCALE.CODE}`,
];
