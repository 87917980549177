import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { fromEvent, merge, of } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

const ACTIVE_EVENTS = [
  'mousemove',
  'keydown',
  'wheel',
  'DOMMouseScroll',
  'mouseWheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'MSPointerDown',
  'MSPointerMove',
  'visibilitychange',
];

const fromEvents = (target: Document, events: string[]) =>
  events.reduce((observable$, eventName) => merge(observable$, fromEvent(target, eventName)), of());

function useIdleTimer({ timeout, onTimeout }: { timeout: number; onTimeout: () => void }) {
  const [active, setActive] = useState(false);
  const start = useCallback(() => setActive(true), []);
  const stop = useCallback(() => setActive(false), []);

  const timeoutHandler = useRef(onTimeout);
  useEffect(() => {
    timeoutHandler.current = onTimeout;
  }, [onTimeout]);

  useEffect(() => {
    if (active) {
      let timeoutId: Nullable<ReturnType<typeof setTimeout>> = null;
      const messageHandler = () => {
        if (typeof timeoutId === 'number') {
          clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => timeoutHandler.current(), timeout);
      };

      const channel = new BroadcastChannel('idle-timer');
      channel.onmessage = messageHandler;

      const eventSubscription$ = fromEvents(document, ACTIVE_EVENTS)
        .pipe(throttleTime(1000))
        .subscribe(() => {
          /* the message content is meaningless */
          channel.postMessage('');
          /* manually call handler because the sender tab will not received the message from itself */
          messageHandler();
        });

      return () => {
        channel.close();
        eventSubscription$.unsubscribe();
        if (timeoutId) clearTimeout(timeoutId);
      };
    }
  }, [active, timeout]);

  return useMemo(() => ({ start, stop }), [start, stop]);
}

export default useIdleTimer;
