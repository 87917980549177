import { Epic, ofType } from 'redux-observable';
import { from, of, timer } from 'rxjs';
import { catchError, filter, map, mapTo, switchMap, takeUntil } from 'rxjs/operators';

import { apiGetMyNotifications, apiReadMyNotifications } from '@/platforms/endpoints/notification';
import { cancelExtendAccessToken, handleSessionTimeout } from '@/redux/AuthProvider/actions';

import {
  checkUnreadNotificationExisting,
  checkUnreadNotificationExistingError,
  checkUnreadNotificationExistingSuccess,
  getNotificationList,
  getNotificationListError,
  pollingCheckUnreadNotifications,
  readAllNotifications,
  readAllNotificationsError,
  readAllNotificationsSuccess,
  readSpecificNotification,
  readSpecificNotificationError,
  readSpecificNotificationSuccess,
  receivedNotificationList,
} from './actions';

const getNotificationListEpic: Epic = action$ =>
  action$.pipe(
    filter(getNotificationList.match),
    switchMap(() =>
      apiGetMyNotifications().pipe(
        map(response => receivedNotificationList(response)),
        catchError(error => of(getNotificationListError(error), handleSessionTimeout(error)))
      )
    )
  );

const readAllNotificationsEpic: Epic = action$ =>
  action$.pipe(
    filter(readAllNotifications.match),
    switchMap(() =>
      // this api will only read all notifications has no embedded destination URL
      apiReadMyNotifications().pipe(
        // no matter succeed or failed, get notification list again
        switchMap(() => of(getNotificationList(), readAllNotificationsSuccess())),
        catchError(error => of(getNotificationList(), readAllNotificationsError(error), handleSessionTimeout(error)))
      )
    )
  );

const readSpecificNotificationEpic: Epic = action$ =>
  action$.pipe(
    filter(readSpecificNotification.match),
    switchMap(({ payload: notificationId }) =>
      apiReadMyNotifications({
        notification_ids: [notificationId],
      }).pipe(
        map(() =>
          // TODO: need to check unread notification again when if infinite scroll mode
          readSpecificNotificationSuccess(notificationId)
        ),
        catchError(error => of(readSpecificNotificationError(error), handleSessionTimeout(error)))
      )
    )
  );

const checkUnreadNotificationExistingEpic: Epic = action$ =>
  action$.pipe(
    filter(checkUnreadNotificationExisting.match),
    switchMap(() =>
      apiGetMyNotifications({
        is_read: false,
        limit: 1,
      }).pipe(
        map(response => checkUnreadNotificationExistingSuccess(response.length > 0)),
        catchError(error => from([checkUnreadNotificationExistingError(error), handleSessionTimeout(error)]))
      )
    )
  );

const pollingCheckUnreadNotificationsEpic: Epic = action$ =>
  action$.pipe(
    filter(pollingCheckUnreadNotifications.match),
    switchMap(() =>
      timer(0, 5000).pipe(
        mapTo(checkUnreadNotificationExisting()),
        takeUntil(action$.pipe(ofType(cancelExtendAccessToken.type)))
      )
    )
  );
export default [
  getNotificationListEpic,
  readAllNotificationsEpic,
  readSpecificNotificationEpic,
  checkUnreadNotificationExistingEpic,
  pollingCheckUnreadNotificationsEpic,
];
