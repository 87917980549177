import { useLayoutEffect, useRef } from 'react';
import { v4 } from 'uuid';

import { Uuid } from '@/interfaces/id';

let originalBodyOverflowStyle: string;

enum USAGE_STATE {
  LOCKED = 'locked',
  UNLOCKED = 'unlocked',
  MOUNTED = 'mounted',
  UNMOUNTED = 'unmounted',
}

const trackedUsage: Record<Uuid, USAGE_STATE> = {};

const tryRestoreBodyOverflowStyle = () => {
  if (Object.values(trackedUsage).some(state => state === USAGE_STATE.LOCKED)) return;
  document.body.style.overflow = originalBodyOverflowStyle;
};

const useBodyScrollLock = (toggleCondition: boolean) => {
  const idRef = useRef<Uuid>(v4());

  useLayoutEffect(() => {
    if (originalBodyOverflowStyle === undefined) {
      originalBodyOverflowStyle = document.body.style.overflow;
    }
    if (toggleCondition) {
      document.body.style.overflow = 'hidden';
    }
  }, [toggleCondition]);

  useLayoutEffect(() => {
    const id = idRef.current;
    trackedUsage[id] ??= USAGE_STATE.MOUNTED;
    return () => {
      trackedUsage[id] = USAGE_STATE.UNMOUNTED;
      tryRestoreBodyOverflowStyle();
    };
  }, []);

  useLayoutEffect(() => {
    const id = idRef.current;
    trackedUsage[id] = toggleCondition ? USAGE_STATE.LOCKED : USAGE_STATE.UNLOCKED;
    tryRestoreBodyOverflowStyle();
  }, [toggleCondition]);
};

export default useBodyScrollLock;
