const ODP_GENERAL = 'odp_general';
const ODP_PMAPP = 'odp_pmapp';
const ODP_SYSADMIN = 'odp_sysadmin';
const ODP_INDGIAPP = 'odp_indgiapp';
const ODP_MEMBER = 'odg_member';
const ODP_CAMPAIGN = 'odp_campaign';
const EVENT = 'event';
const RESOURCE = 'resource';
const NOTIFICATION = 'notification';

type PlatformSpecificNamespaces = {
  ODP_CIGNATW?: string;
  ODP_FI?: string;
};

const namespaces = {
  EVENT,
  RESOURCE,
  NOTIFICATION,
  ODP_GENERAL,
  ODP_PMAPP,
  ODP_SYSADMIN,
  ODP_INDGIAPP,
  ODP_MEMBER,
  ODP_CAMPAIGN,
  ALL_I18N_NAMESPACES: [
    EVENT,
    RESOURCE,
    NOTIFICATION,
    ODP_GENERAL,
    ODP_PMAPP,
    ODP_SYSADMIN,
    ODP_INDGIAPP,
    ODP_MEMBER,
    ODP_CAMPAIGN,
  ],
  I18N_DOWNLOAD_PROJECT: 'ixt',
};

type Namespaces = PlatformSpecificNamespaces & typeof namespaces;
export default namespaces as Namespaces;
