import Urls from '@/constants/urls';
import { OD_ENUM_ME_NOTIFICATIONS__TYPE } from '@/platforms/interfaces/odg/od-enum/meNotifications';
import { NotificationData, NotificationLinkInfo } from '@/redux/NotificationProvider/interfaces';

export const mapNotificationTypeToLinkInfo = (
  type: OD_ENUM_ME_NOTIFICATIONS__TYPE,
  data: NotificationData
): NotificationLinkInfo => {
  switch (type) {
    case OD_ENUM_ME_NOTIFICATIONS__TYPE.PRODUCT_APPROVAL:
    case OD_ENUM_ME_NOTIFICATIONS__TYPE.PRODUCT_LAUNCH:
    case OD_ENUM_ME_NOTIFICATIONS__TYPE.PRODUCT_RETURN_TO_DRAFT:
    case OD_ENUM_ME_NOTIFICATIONS__TYPE.PRODUCT_REVIEW:
    case OD_ENUM_ME_NOTIFICATIONS__TYPE.PRODUCT_SHELVE: {
      const productId = data.product_id;
      return {
        to: Urls.product.BasicSettings,
        query: { productId: productId.toString() },
      };
    }
    // TODO: fix: BE unintentional remove change request related schema(OIP-9109)
    // case OD_ENUM_ME_NOTIFICATIONS__TYPE.POLICY_CHANGE_REQUEST_WITHDRAW:
    // case OD_ENUM_ME_NOTIFICATIONS__TYPE.POLICY_CHANGE_REQUEST_APPROVE:
    // case OD_ENUM_ME_NOTIFICATIONS__TYPE.POLICY_CHANGE_REQUEST_REJECT: {
    //   const policyNumber = data.policy_number;
    //   return {
    //     to: Urls.personalPolicy.PolicyInfo,
    //     query: { policyNumber: policyNumber.toString() },
    //   };
    // }
    // case OD_ENUM_ME_NOTIFICATIONS__TYPE.POLICY_CHANGE_REQUEST_SUBMIT: {
    //   const policyNumber = data.policy_number;
    //   return {
    //     to: Urls.personalPolicy.ChangeRequests,
    //     query: { policyNumber: policyNumber.toString() },
    //   };
    // }
    case OD_ENUM_ME_NOTIFICATIONS__TYPE.CAMPAIGN_CHANGE_REQUEST_SUBMIT:
    case OD_ENUM_ME_NOTIFICATIONS__TYPE.CAMPAIGN_CHANGE_REQUEST_WITHDRAW:
    case OD_ENUM_ME_NOTIFICATIONS__TYPE.CAMPAIGN_CHANGE_REQUEST_APPROVE:
    case OD_ENUM_ME_NOTIFICATIONS__TYPE.CAMPAIGN_CHANGE_REQUEST_REJECT: {
      const campaignId = data.campaign_id;
      return {
        to: Urls.campaign.ChangeRequests,
        query: { campaignId: campaignId.toString() },
      };
    }
    case OD_ENUM_ME_NOTIFICATIONS__TYPE.CAMPAIGN_SUBMIT:
    case OD_ENUM_ME_NOTIFICATIONS__TYPE.CAMPAIGN_SEND_BACK:
    case OD_ENUM_ME_NOTIFICATIONS__TYPE.CAMPAIGN_APPROVE:
    case OD_ENUM_ME_NOTIFICATIONS__TYPE.CAMPAIGN_LAUNCH:
    case OD_ENUM_ME_NOTIFICATIONS__TYPE.CAMPAIGN_SHELVE: {
      const campaignId = data.campaign_id;
      return {
        to: Urls.campaign.CampaignDetails,
        query: { campaignId: campaignId.toString() },
      };
    }
    default:
      return {};
  }
};

export const mapNotificationTypeToTitleI18nKey = (dataType: OD_ENUM_ME_NOTIFICATIONS__TYPE): string =>
  ({
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.PRODUCT_APPROVAL]: 'product.sidebar_notifications.title_product_approved',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.PRODUCT_LAUNCH]: 'product.sidebar_notifications.title_product_launched',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.PRODUCT_RETURN_TO_DRAFT]:
      'product.sidebar_notifications.title_product_returned_to_draft',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.PRODUCT_REVIEW]:
      'product.sidebar_notifications.title_product_draft_ready_for_review',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.PRODUCT_SHELVE]: 'product.sidebar_notifications.title_product_shelved',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.WELCOME]: 'notif_welcome_title',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.QUOTATION_ASSIGN]: 'notif_underwritingnotif_type1_title',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.QUOTATION_REVIEW]: 'notif_underwritingnotif_type2_title',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.QUOTATION_SEND_BACK]: 'notif_underwritingnotif_type3_title',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.QUOTATION_APPROVE]: 'notif_underwritingnotif_type4_title',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.POLICY_CHANGE_REQUEST_SUBMIT]:
      'policy.sidebar_notifications.title_change_request_submitted',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.POLICY_CHANGE_REQUEST_WITHDRAW]:
      'policy.sidebar_notifications.title_change_request_withdrawn',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.POLICY_CHANGE_REQUEST_APPROVE]:
      'policy.sidebar_notifications.title_change_request_approved',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.POLICY_CHANGE_REQUEST_REJECT]:
      'policy.sidebar_notifications.title_change_request_rejected',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.CAMPAIGN_CHANGE_REQUEST_SUBMIT]:
      'campaign.sidebar_notifications.title_change_request_submitted',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.CAMPAIGN_CHANGE_REQUEST_WITHDRAW]:
      'campaign.sidebar_notifications.title_change_request_withdrawn',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.CAMPAIGN_CHANGE_REQUEST_APPROVE]:
      'campaign.sidebar_notifications.title_change_request_approved',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.CAMPAIGN_CHANGE_REQUEST_REJECT]:
      'campaign.sidebar_notifications.title_change_request_rejected',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.CAMPAIGN_SUBMIT]: 'campaign.sidebar_notifications.title_ready_for_review',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.CAMPAIGN_SEND_BACK]: 'campaign.sidebar_notifications.title_returned_to_draft',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.CAMPAIGN_APPROVE]: 'campaign.sidebar_notifications.title_approved',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.CAMPAIGN_LAUNCH]: 'campaign.sidebar_notifications.title_launched',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.CAMPAIGN_SHELVE]: 'campaign.sidebar_notifications.title_shelved',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.MEMBER_CHANGE_REQUEST_SUBMIT]:
      'member.sidebar_notifications.title_change_request_submitted',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.MEMBER_CHANGE_REQUEST_WITHDRAW]:
      'member.sidebar_notifications.title_change_request_withdrawn',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.MEMBER_CHANGE_REQUEST_APPROVE]:
      'member.sidebar_notifications.title_change_request_approved',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.MEMBER_CHANGE_REQUEST_REJECT]:
      'member.sidebar_notifications.title_change_request_rejected',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.CLAIM_SUBMIT_ADJUDICATING]: 'notif_claimnotif_type1_title',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.CLAIM_ASK_PENDING_INFO]: 'notif_claimnotif_type1_title',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.CLAIM_SUBMIT_REVIEW]: 'notif_claimnotif_type2_title',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.CLAIM_APPROVE]: 'notif_claimnotif_type3_title',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.CLAIM_SENDBACK_ADJUDICATING]: 'notif_claimnotif_type4_title',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.CLAIM_SUBMIT_PENDING_INFO]: 'notif_claimnotif_type5_title',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.CLAIM_WITHDRAW]: 'notif_claimnotif_type6_title',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.CLAIM_ASSIGN_INTERNAL_USER]: 'notif_claimnotif_type7_title',
  }[dataType]);

export const mapNotificationTypeToDescriptionI18nKey = (dataType: OD_ENUM_ME_NOTIFICATIONS__TYPE): string =>
  ({
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.PRODUCT_APPROVAL]: 'product.sidebar_notifications.text_product_is_approved',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.PRODUCT_LAUNCH]: 'product.sidebar_notifications.text_product_is_launched',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.PRODUCT_RETURN_TO_DRAFT]:
      'product.sidebar_notifications.text_product_returned_to_draft',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.PRODUCT_REVIEW]:
      'product.sidebar_notifications.text_product_draft_ready_for_review',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.PRODUCT_SHELVE]: 'product.sidebar_notifications.text_product_is_shelved',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.WELCOME]: 'notif_welcome_desc',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.QUOTATION_ASSIGN]: 'notif_underwritingnotif_type1_desc',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.QUOTATION_REVIEW]: 'notif_underwritingnotif_type2_desc',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.QUOTATION_SEND_BACK]: 'notif_underwritingnotif_type3_desc',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.QUOTATION_APPROVE]: 'notif_underwritingnotif_type4_desc',
    // TODO: fix: BE unintentional remove change request related schema(OIP-9109)
    // [OD_ENUM_ME_NOTIFICATIONS__TYPE.POLICY_CHANGE_REQUEST_SUBMIT]: 'policy.sidebar_notifications.text_change_request_submitted',
    // [OD_ENUM_ME_NOTIFICATIONS__TYPE.POLICY_CHANGE_REQUEST_WITHDRAW]: 'policy.sidebar_notifications.text_change_request_withdrawn',
    // [OD_ENUM_ME_NOTIFICATIONS__TYPE.POLICY_CHANGE_REQUEST_APPROVE]: 'policy.sidebar_notifications.text_change_request_approved',
    // [OD_ENUM_ME_NOTIFICATIONS__TYPE.POLICY_CHANGE_REQUEST_REJECT]: 'policy.sidebar_notifications.text_change_request_rejected',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.CAMPAIGN_CHANGE_REQUEST_SUBMIT]:
      'campaign.sidebar_notifications.text_change_request_submitted',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.CAMPAIGN_CHANGE_REQUEST_WITHDRAW]:
      'campaign.sidebar_notifications.text_change_request_withdrawn',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.CAMPAIGN_CHANGE_REQUEST_APPROVE]:
      'campaign.sidebar_notifications.text_change_request_approved',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.CAMPAIGN_CHANGE_REQUEST_REJECT]:
      'campaign.sidebar_notifications.text_change_request_rejected',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.CAMPAIGN_SUBMIT]: 'campaign.sidebar_notifications.text_ready_for_review',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.CAMPAIGN_SEND_BACK]: 'campaign.sidebar_notifications.text_returned_to_draft',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.CAMPAIGN_APPROVE]: 'campaign.sidebar_notifications.text_approved',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.CAMPAIGN_LAUNCH]: 'campaign.sidebar_notifications.text_launched',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.CAMPAIGN_SHELVE]: 'campaign.sidebar_notifications.text_shelved',
    // TODO: fix: BE unintentional remove change request related schema(OIP-9109)
    // [OD_ENUM_ME_NOTIFICATIONS__TYPE.MEMBER_CHANGE_REQUEST_SUBMIT]: 'member.sidebar_notifications.text_change_request_submitted',
    // [OD_ENUM_ME_NOTIFICATIONS__TYPE.MEMBER_CHANGE_REQUEST_WITHDRAW]: 'member.sidebar_notifications.text_change_request_withdrawn',
    // [OD_ENUM_ME_NOTIFICATIONS__TYPE.MEMBER_CHANGE_REQUEST_APPROVE]: 'member.sidebar_notifications.text_change_request_approved',
    // [OD_ENUM_ME_NOTIFICATIONS__TYPE.MEMBER_CHANGE_REQUEST_REJECT]: 'member.sidebar_notifications.text_change_request_rejected',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.CLAIM_SUBMIT_ADJUDICATING]: 'notif_claimnotif_type1_desc',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.CLAIM_ASK_PENDING_INFO]: 'notif_claimnotif_type1_desc',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.CLAIM_SUBMIT_REVIEW]: 'notif_claimnotif_type2_desc',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.CLAIM_APPROVE]: 'notif_claimnotif_type3_desc',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.CLAIM_SENDBACK_ADJUDICATING]: 'notif_claimnotif_type4_desc',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.CLAIM_SUBMIT_PENDING_INFO]: 'notif_claimnotif_type5_desc',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.CLAIM_WITHDRAW]: 'notif_claimnotif_type6_desc',
    [OD_ENUM_ME_NOTIFICATIONS__TYPE.CLAIM_ASSIGN_INTERNAL_USER]: 'notif_claimnotif_type7_desc',
  }[dataType]);
