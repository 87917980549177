import { Epic } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';

import SCHEMA_COUNTRY from '@/constants/schema/country';
import SCHEMA_TIMEZONE from '@/constants/schema/timezone';
import {
  apiGetCallingCodes,
  apiGetCountries,
  apiGetCurrencies,
  apiGetLocales,
  apiGetMarkets,
  apiGetPlatform,
  apiGetTimezone,
} from '@/services/OneDegree/misc';

import { handleSessionTimeout } from '../AuthProvider/actions';
import {
  getCallingCodeList,
  getCallingCodeListError,
  getCountryList,
  getCountryListError,
  getCurrencyList,
  getCurrencyListError,
  getLocaleList,
  getLocaleListError,
  getMarketList,
  getMarketListError,
  getPlatformList,
  getPlatformListError,
  getTimezoneList,
  getTimezoneListError,
  receivedCallingCodeList,
  receivedCountryList,
  receivedCurrencyList,
  receivedLocaleList,
  receivedMarketList,
  receivedPlatformList,
  receivedTimezoneList,
} from './actions';
import {
  FIELDS_CALLING_CODE_LIST,
  FIELDS_COUNTRY_LIST,
  FIELDS_CURRENCY_LIST,
  FIELDS_LOCALE_LIST,
  FIELDS_MARKET_LIST,
  FIELDS_PLATFORM_LIST,
  FIELDS_TIMEZONE_LIST,
} from './fields';
import {
  IApiCallingCode,
  IApiCountry,
  IApiCurrency,
  IApiLocale,
  IApiMarket,
  IApiPlatform,
  IApiTimezone,
} from './interfaces';
import {
  callingCodeListSelector,
  countryListSelector,
  currencyListSelector,
  localeListSelector,
  marketListSelector,
  platformListSelector,
  timezoneListSelector,
} from './selectors';

const getCallingCodeListEpic: Epic = (action$, state$) =>
  action$.pipe(
    filter(getCallingCodeList.match),
    filter(() => {
      const callingCodeList = callingCodeListSelector(state$.value);
      return callingCodeList.length === 0;
    }),
    switchMap(() =>
      apiGetCallingCodes<IApiCallingCode[]>({
        fields: FIELDS_CALLING_CODE_LIST,
      }).pipe(
        map(({ data }) => receivedCallingCodeList(data)),
        catchError(error => of(getCallingCodeListError(error), handleSessionTimeout(error)))
      )
    )
  );

const getCountryListEpic: Epic = (action$, state$) =>
  action$.pipe(
    filter(getCountryList.match),
    filter(() => {
      const countryList = countryListSelector(state$.value);
      return countryList.length === 0;
    }),
    switchMap(() =>
      apiGetCountries<IApiCountry[]>({
        fields: FIELDS_COUNTRY_LIST,
        orderBy: SCHEMA_COUNTRY.NAME,
      }).pipe(
        map(({ data }) => receivedCountryList(data)),
        catchError(error => of(getCountryListError(error), handleSessionTimeout(error)))
      )
    )
  );

const getCurrencyListEpic: Epic = (action$, state$) =>
  action$.pipe(
    filter(getCurrencyList.match),
    filter(() => {
      const currencyList = currencyListSelector(state$.value);
      return currencyList.length === 0;
    }),
    switchMap(() =>
      apiGetCurrencies<IApiCurrency[]>({
        fields: FIELDS_CURRENCY_LIST,
      }).pipe(
        map(({ data }) => receivedCurrencyList(data)),
        catchError(error => of(getCurrencyListError(error), handleSessionTimeout(error)))
      )
    )
  );

const getMarketListEpic: Epic = (action$, state$) =>
  action$.pipe(
    filter(getMarketList.match),
    filter(() => {
      const marketList = marketListSelector(state$.value);
      return marketList.length === 0;
    }),
    switchMap(() =>
      apiGetMarkets<IApiMarket[]>({
        fields: FIELDS_MARKET_LIST,
      }).pipe(
        map(({ data }) => receivedMarketList(data)),
        catchError(error => of(getMarketListError(error), handleSessionTimeout(error)))
      )
    )
  );

const getTimezoneListEpic: Epic = (action$, state$) =>
  action$.pipe(
    filter(getTimezoneList.match),
    filter(() => {
      const timezoneList = timezoneListSelector(state$.value);
      return timezoneList.length === 0;
    }),
    switchMap(() =>
      apiGetTimezone<IApiTimezone[]>({
        fields: FIELDS_TIMEZONE_LIST,
        orderBy: SCHEMA_TIMEZONE.NAME,
      }).pipe(
        map(({ data }) => receivedTimezoneList(data)),
        catchError(error => of(getTimezoneListError(error), handleSessionTimeout(error)))
      )
    )
  );

const getPlatformListEpic: Epic = (action$, state$) =>
  action$.pipe(
    filter(getPlatformList.match),
    filter(() => {
      const platformList = platformListSelector(state$.value);
      return platformList.length === 0;
    }),
    switchMap(() =>
      apiGetPlatform<IApiPlatform[]>({
        fields: FIELDS_PLATFORM_LIST,
      }).pipe(
        map(({ data }) => receivedPlatformList(data)),
        catchError(error => of(getPlatformListError(error), handleSessionTimeout(error)))
      )
    )
  );

const getLocaleListEpic: Epic = (action$, state$) =>
  action$.pipe(
    filter(getLocaleList.match),
    filter(() => {
      const localeList = localeListSelector(state$.value);
      return localeList.length === 0;
    }),
    switchMap(() =>
      apiGetLocales<IApiLocale[]>({
        fields: FIELDS_LOCALE_LIST,
      }).pipe(
        map(({ data }) => receivedLocaleList(data)),
        catchError(error => of(getLocaleListError(error), handleSessionTimeout(error)))
      )
    )
  );

export default [
  getCallingCodeListEpic,
  getCountryListEpic,
  getCurrencyListEpic,
  getMarketListEpic,
  getTimezoneListEpic,
  getPlatformListEpic,
  getLocaleListEpic,
];
