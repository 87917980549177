import { atom } from 'jotai';

import { IPermission } from '@/interfaces/auth';

export const isLoggedInAtom = atom<boolean>(false);

export const isLoggingOutAtom = atom<boolean>(false);

export const accessTokenAtom = atom<Nullable<string>>(null);

export const refreshTokenAtom = atom<Nullable<string>>(null);

export const internalUserIdAtom = atom<number | undefined>(undefined);

export const permissionsAtom = atom<IPermission[]>([]);
