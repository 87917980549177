import { createSelector } from 'reselect';

import { AUTHENTICATION_TYPE } from '@/constants/authentication';
import { PERMISSION } from '@/constants/permission';
import { IncrementalId } from '@/interfaces/id';
import { RootState } from '@/interfaces/redux';
import noNulls from '@/utils/noNulls';

const selectAuth = (state: RootState) => state.auth;

export const isSessionTimeoutSelector = createSelector(selectAuth, content => content.isSessionTimeout);

export const isPermissionDeniedSelector = createSelector(selectAuth, content => content.isPermissionDenied);

export const isSigningSelector = createSelector(selectAuth, content => content.isSigning);

export const isResetPasswordEmailSendingSelector = createSelector(
  selectAuth,
  content => content.isSendingResetPassword
);

export const expirationTimeSelector = createSelector(selectAuth, content => content.accessToken?.expiration_time);

export const meSelector = createSelector(selectAuth, content => content.me);

export const isMeLoadingSelector = createSelector(selectAuth, content => content.isLoadingMe);

export const isMyPermissionsLoadedSelector = createSelector(
  [selectAuth, isMeLoadingSelector],
  (content, isLoading) => !isLoading && typeof content.myPermissions !== 'undefined'
);

export const activeAuthenticationsSelector = createSelector(selectAuth, content => {
  if (!content.activeAuthentications) {
    return [];
  }

  const activeAuthentications = content.activeAuthentications.map(({ id, discriminator }) => {
    const type = {
      email: AUTHENTICATION_TYPE.EMAIL,
      ldap: AUTHENTICATION_TYPE.LDAP,
    }[discriminator];

    if (!type) return null;
    return { id, type };
  });
  return noNulls(activeAuthentications);
});

/**
 * WARNING: DO NOT use this selector in feature, because the privilege for superuser will be ignored.
 *          Should make a standalone selector with `makePermissionSelector()` in below.
 */
export const myPermissionsSelector = createSelector(selectAuth, content => content.myPermissions || new Set<string>());

export const myModulesSelector = createSelector(selectAuth, content => content.myModules || new Set<string>());

export const myNameSelector = createSelector(meSelector, content => content?.full_name || '');

export const myEmailSelector = createSelector(meSelector, content => content?.email_address || '');

export const myLastLoginTimeSelector = createSelector(meSelector, content => content?.last_login_time || '');

export const myIdSelector = createSelector(meSelector, content => content?.id ?? (0 as IncrementalId));

export const myTeamIdsSelector = createSelector(meSelector, content => (content?.teams || []).map(team => team.id));

export const myRoleIdsSelector = createSelector(meSelector, content => (content?.roles || []).map(role => role.id));

const makePermissionSelector = (permission: string) =>
  createSelector(myPermissionsSelector, content => content.has(permission));

// PRODUCT
export const isAbleToCreateProductSelector = makePermissionSelector(PERMISSION.PRODUCT__EDIT_PRODUCT);
export const isAbleToViewProductSelector = makePermissionSelector(PERMISSION.PRODUCT__VIEW_PRODUCT);
export const isAbleToEditProductSelector = makePermissionSelector(PERMISSION.PRODUCT__EDIT_PRODUCT);
export const isAbleToDeleteProductSelector = makePermissionSelector(PERMISSION.PRODUCT__DELETE_PRODUCT);
export const isAbleToSubmitProductSelector = makePermissionSelector(PERMISSION.PRODUCT__EDIT_PRODUCT);
export const isAbleToApproveProductSelector = makePermissionSelector(PERMISSION.PRODUCT__REVIEW_PRODUCT);
export const isAbleToLaunchProductSelector = makePermissionSelector(PERMISSION.PRODUCT__EDIT_PRODUCT);
export const isAbleToShelveProductSelector = makePermissionSelector(PERMISSION.PRODUCT__EDIT_PRODUCT);

// LIBRARY
export const isAbleToEditLibrarySelector = makePermissionSelector(PERMISSION.PRODUCT__EDIT_LIBRARY);
export const isAbleToViewLibrarySelector = makePermissionSelector(PERMISSION.PRODUCT__VIEW_LIBRARY);

// PRODUCT TEMPLATE
export const isAbleToViewProductTemplateSelector = makePermissionSelector(PERMISSION.PRODUCT__VIEW_LIBRARY);
export const isAbleToEditProductTemplateSelector = makePermissionSelector(PERMISSION.PRODUCT__EDIT_LIBRARY);

// SYSTEM
export const isAbleToViewAdminSelector = makePermissionSelector(PERMISSION.ADMIN__VIEW_ADMIN_SETTINGS);
export const isAbleToCreateAuthenticationSelector = makePermissionSelector(PERMISSION.ADMIN__EDIT_ADMIN_SETTINGS);
export const isAbleToEditAuthenticationSelector = makePermissionSelector(PERMISSION.ADMIN__EDIT_ADMIN_SETTINGS);
export const isAbleToDeleteAuthenticationSelector = makePermissionSelector(PERMISSION.ADMIN__DELETE_ADMIN_SETTINGS);
export const isAbleToAddInternalUserSelector = makePermissionSelector(PERMISSION.USER__EDIT_USER);
export const isAbleToLockInternalUserSelector = makePermissionSelector(PERMISSION.USER__CHANGE_USER_STATUS);
export const isAbleToUnlockInternalUserSelector = makePermissionSelector(PERMISSION.USER__CHANGE_USER_STATUS);
export const isAbleToReactivateInternalUserSelector = makePermissionSelector(PERMISSION.USER__CHANGE_USER_STATUS);
export const isAbleToDeactivateInternalUserSelector = makePermissionSelector(PERMISSION.USER__CHANGE_USER_STATUS);
export const isAbleToEditInternalUserSelector = makePermissionSelector(PERMISSION.USER__EDIT_USER);
export const isAbleToViewInternalUserSelector = makePermissionSelector(PERMISSION.USER__VIEW_USER);
export const isAbleToViewInternalUserActivityLogSelector = makePermissionSelector(PERMISSION.SYSTEM__VIEW_ACTIVITY_LOG);
export const isAbleToViewRoleSelector = makePermissionSelector(PERMISSION.USER__VIEW_USER);
export const isAbleToCreateRoleSelector = makePermissionSelector(PERMISSION.USER__EDIT_USER);
export const isAbleToEditRoleSelector = makePermissionSelector(PERMISSION.USER__EDIT_USER);
export const isAbleToDeleteRoleSelector = makePermissionSelector(PERMISSION.USER__DELETE_USER);
export const isAbleToViewTeamSelector = makePermissionSelector(PERMISSION.USER__VIEW_USER);
export const isAbleToCreateTeamSelector = makePermissionSelector(PERMISSION.USER__EDIT_USER);
export const isAbleToEditTeamSelector = makePermissionSelector(PERMISSION.USER__EDIT_USER);
export const isAbleToDeleteTeamSelector = makePermissionSelector(PERMISSION.USER__DELETE_USER);

// EVENT SUBSCRIPTION
export const isAbleToViewEventSubscriptionSelector = makePermissionSelector(PERMISSION.EVENT__VIEW_EVENT_SETTINGS);
export const isAbleToCreateEventSubscriptionSelector = makePermissionSelector(PERMISSION.EVENT__EDIT_EVENT_SETTINGS);
export const isAbleToEditEventSubscriptionSelector = makePermissionSelector(PERMISSION.EVENT__EDIT_EVENT_SETTINGS);
export const isAbleToDeleteEventSubscriptionSelector = makePermissionSelector(PERMISSION.EVENT__DELETE_EVENT_SETTINGS);

// POLICY
export const isAbleToViewPolicySelector = makePermissionSelector(PERMISSION.POLICY__VIEW_POLICY);
export const isAbleToCreatePolicyChangeRequestSelector = makePermissionSelector(PERMISSION.POLICY__EDIT_POLICY);
export const isAbleToApprovePolicyChangeRequestSelector = makePermissionSelector(
  PERMISSION.POLICY__REVIEW_CHANGE_REQUEST
);
export const isAbleToUpdatePolicyStatusSelector = makePermissionSelector(PERMISSION.POLICY__CHANGE_POLICY_STATUS);

// MEMBER
export const isAbleToEditMemberInfoSelector = makePermissionSelector(PERMISSION.MEMBER__EDIT_MEMBER);
export const isAbleToEditMemberAccountInfoSelector = makePermissionSelector(PERMISSION.MEMBER__EDIT_MEMBER);
export const isAbleToDeactivateMemberEndUserSelector = makePermissionSelector(PERMISSION.MEMBER__CHANGE_MEMBER_STATUS);
export const isAbleToActivateMemberEndUserSelector = makePermissionSelector(PERMISSION.MEMBER__CHANGE_MEMBER_STATUS);
export const isAbleToLockMemberEndUserSelector = makePermissionSelector(PERMISSION.MEMBER__CHANGE_MEMBER_STATUS);
export const isAbleToUnlockMemberEndUserSelector = makePermissionSelector(PERMISSION.MEMBER__CHANGE_MEMBER_STATUS);

// CAMPAIGN
export const isAbleToCreateCampaignSelector = makePermissionSelector(PERMISSION.CAMPAIGN__EDIT_CAMPAIGN);
export const isAbleToDeleteCampaignSelector = makePermissionSelector(PERMISSION.CAMPAIGN__EDIT_CAMPAIGN);
export const isAbleToEditCampaignSelector = makePermissionSelector(PERMISSION.CAMPAIGN__EDIT_CAMPAIGN);
export const isAbleToSubmitCampaignSelector = makePermissionSelector(PERMISSION.CAMPAIGN__EDIT_CAMPAIGN);
export const isAbleToApproveCampaignSelector = makePermissionSelector(PERMISSION.CAMPAIGN__REVIEW_CAMPAIGN);
export const isAbleToLaunchCampaignSelector = makePermissionSelector(PERMISSION.CAMPAIGN__EDIT_CAMPAIGN);
export const isAbleToShelveCampaignSelector = makePermissionSelector(PERMISSION.CAMPAIGN__EDIT_CAMPAIGN);
export const isAbleToCreateCampaignChangeRequestSelector = makePermissionSelector(PERMISSION.CAMPAIGN__EDIT_CAMPAIGN);
export const isAbleToApproveOrRejectCampaignChangeRequestSelector = makePermissionSelector(
  PERMISSION.CAMPAIGN__REVIEW_CAMPAIGN
);

// NETWORK
export const isAbleToCreateNetworkSelector = makePermissionSelector(PERMISSION.NETWORK__CREATE_NETWORK);
export const isAbleToViewNetworkSelector = makePermissionSelector(PERMISSION.NETWORK__VIEW_NETWORK);
export const isAbleToEditNetworkSelector = makePermissionSelector(PERMISSION.NETWORK__EDIT_NETWORK);
export const isAbleToDeleteNetworkSelector = makePermissionSelector(PERMISSION.NETWORK__DELETE_NETWORK);
export const isAbleToCreateServiceProviderSelector = makePermissionSelector(PERMISSION.NETWORK__EDIT_PROVIDER);
export const isAbleToViewServiceProviderSelector = makePermissionSelector(PERMISSION.NETWORK__VIEW_PROVIDER);
export const isAbleToEditServiceProviderSelector = makePermissionSelector(PERMISSION.NETWORK__EDIT_PROVIDER);
export const isAbleToDeleteServiceProviderSelector = makePermissionSelector(PERMISSION.NETWORK__EDIT_PROVIDER);

// APPLICATION
export const isAbleToEditApplicationSelector = makePermissionSelector(PERMISSION.APPLICATION__EDIT_APPLICATION);
export const isAbleToUnderwriteApplicationSelector = makePermissionSelector(
  PERMISSION.APPLICATION__UNDERWRITE_APPLICATION
);
export const isAbleToReviewApplicationSelector = makePermissionSelector(PERMISSION.APPLICATION__REVIEW_APPLICATION);
