import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { ApiResponseError } from '@/constants/errors';
import { AUTH_WEB_URL } from '@/services/OneDegree/constants';
import { authQueryFn } from '@/services/ReactQuery/fetchers';
import { StringifyQueryKey } from '@/services/ReactQuery/interfaces';

type AuthServiceOptions<TQueryFnData, TData = TQueryFnData> = Omit<
  UseQueryOptions<TQueryFnData, ApiResponseError, TData, StringifyQueryKey>,
  'queryKey'
>;

const useAuthServiceQuery = <TQueryFnData extends object, TData = TQueryFnData>(
  queryKey: StringifyQueryKey,
  options?: AuthServiceOptions<TQueryFnData, TData>,
  searchParams?: Record<string, string>
) =>
  useQuery<TQueryFnData, ApiResponseError, TData, StringifyQueryKey>(
    queryKey,
    () => authQueryFn(AUTH_WEB_URL, queryKey, searchParams),
    options
  );

export default useAuthServiceQuery;
