import { forwardRef } from 'react';
import styled from 'styled-components';
import InlineSVG, { Props as SVGProps } from 'react-inlinesvg';

const StyledInlineSVG = styled(InlineSVG)`
  display: block;
`;

const SVG = forwardRef<SVGElement, SVGProps>(({ src, ...props }, ref) =>
  src ? <StyledInlineSVG innerRef={ref} src={src} cacheRequests {...props} /> : null
);

export default SVG;
