import { createReducer } from '@reduxjs/toolkit';
import { StatusCodes } from 'http-status-codes';
import { AjaxError } from 'rxjs/ajax';

import {
  getActiveAuthentications,
  getMe,
  getMeError,
  handleSessionTimeout,
  receivedAccessToken,
  receivedActiveAuthentications,
  receivedMe,
  sendResetPasswordEmail,
  sendResetPasswordEmailError,
  sendResetPasswordEmailSucceed,
  setIsPermissionDeniedFalse,
  setIsSessionTimeoutFalse,
  setIsSessionTimeoutTrue,
  signIn,
  signInError,
  signInSuccess,
} from './actions';
import { AuthState } from './types';

const initialState: AuthState = {
  isPermissionDenied: false,
  isSessionTimeout: false,
  isLoggedIn: false,
  isSigning: false,
  isSigningOut: false,
  isSendingResetPassword: false,
  isLoadingMe: false,
};

const reducer = createReducer(initialState, builder => {
  builder
    .addCase(signIn, state => {
      state.isSigning = true;
    })
    .addCase(signInError, state => {
      state.isSigning = false;
    })
    .addCase(signInSuccess, state => {
      state.isSigning = false;
    })
    .addCase(getMe, state => {
      state.isLoadingMe = true;
    })
    .addCase(getMeError, state => {
      state.isLoadingMe = false;
    })
    .addCase(receivedMe, (state, { payload }) => {
      state.me = payload;
      state.isLoadingMe = false;

      state.myPermissions = new Set(
        payload.roles.reduce<string[]>((prevMyPermissions, role) => {
          if (!role.permission_sets) {
            return prevMyPermissions;
          }

          return prevMyPermissions.concat(
            role.permission_sets.map(permissionSet => {
              const moduleName = permissionSet.module;
              const permissionKey = permissionSet.key;
              return `${moduleName}.${permissionKey}`;
            })
          );
        }, [])
      );
      state.myModules = new Set(
        payload.roles.reduce<string[]>((prevMyModules, role) => {
          if (!role.permission_sets) {
            return prevMyModules;
          }

          return prevMyModules.concat(role.permission_sets.map(permissionSet => permissionSet.module));
        }, [])
      );
    })
    .addCase(sendResetPasswordEmail, state => {
      state.isSendingResetPassword = true;
    })
    .addCase(sendResetPasswordEmailError, state => {
      state.isSendingResetPassword = false;
    })
    .addCase(sendResetPasswordEmailSucceed, state => {
      state.isSendingResetPassword = false;
    })
    .addCase(receivedAccessToken, state => {
      state.isLoggedIn = true;
    })
    .addCase(getActiveAuthentications, state => {
      delete state.activeAuthentications;
    })
    .addCase(receivedActiveAuthentications, (state, { payload }) => {
      state.activeAuthentications = payload;
    })
    .addCase(setIsSessionTimeoutTrue, state => {
      state.isSessionTimeout = true;
    })
    .addCase(setIsSessionTimeoutFalse, state => {
      state.isSessionTimeout = false;
    })
    .addCase(setIsPermissionDeniedFalse, state => {
      state.isPermissionDenied = false;
    })
    .addCase(handleSessionTimeout, (state, { payload }) => {
      if (!(payload instanceof AjaxError)) return;

      if (payload.status === StatusCodes.UNAUTHORIZED) {
        state.isSessionTimeout = true;
      } else if (payload.status === StatusCodes.FORBIDDEN) {
        state.isPermissionDenied = true;
      }
    });
});

export default reducer;
