import { DEFAULT_PLATFORM } from '@/platforms/constants';

export const IDENTIFIER = DEFAULT_PLATFORM;

export default {
  title: 'IXT Core',
  description: '',
  ogUrl: 'https://internal-web.theixt.com/',
  keywords: 'OneDegree, InsurTech, Insurance',
  ogTitle: '',
  ogImg: '',
  ogSiteName: 'IXT Core',
  ogType: 'website',
  ogDescription: '',
  ogLocale: '',
  canonical: '',
  defaultCountryCode: '',
};
