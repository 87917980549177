import { useEffect } from 'react';
import { useAtomValue } from 'jotai';

import { isLoggedInAtom } from '@/atom/authAtom';
import useIdleTimer from '@/hooks/useIdleTimer';

function useLogoutTimer(onLogout: () => void) {
  const isLoggedIn = useAtomValue(isLoggedInAtom);
  /* TODO: the definition of privileged user is undecided, old definition is deprecated by OIP-1802 */
  const isPrivilegedUser = false;

  /*
   * The logged in session should expire in:
   * 30 minutes for general users
   * 5 minutes for privileged users
   */
  const idleTimer = useIdleTimer({
    timeout: (isPrivilegedUser ? 5 : 30) * 60 * 1000,
    onTimeout: onLogout,
  });
  useEffect(() => {
    if (isLoggedIn) {
      idleTimer.start();
    } else {
      idleTimer.stop();
    }
  }, [idleTimer, isLoggedIn]);
}

export default useLogoutTimer;
