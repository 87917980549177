import { Epic } from 'redux-observable';
import { EMPTY, from, of } from 'rxjs';
import { catchError, filter, mergeMap, takeUntil } from 'rxjs/operators';

import { ALERT_TYPE } from '@/constants/alert';
import { IncrementalId } from '@/interfaces/id';
import { pushAlert } from '@/redux/MiscProvider/actions';
import { asyncDeleteAttachment, asyncUploadAttachment } from '@/services/IxtApi/hooks/attachment';

import { handleSessionTimeout } from '../AuthProvider/actions';
import { cancelUploadAttachment, deleteAttachment, uploadAttachment } from './actions';

type Response = { id: IncrementalId };
const uploadAttachmentEpic: Epic = action$ =>
  action$.pipe(
    filter(uploadAttachment.match),
    mergeMap(({ payload: file, meta: { callbacks } }) => {
      const { success, fail, complete } = callbacks;

      return from(asyncUploadAttachment(file)).pipe(
        mergeMap(data => {
          success?.(data as Response);
          complete?.(data as Response);
          return EMPTY;
        }),
        catchError(err => {
          fail?.(err);
          complete?.(undefined, err);
          return of(
            pushAlert({
              type: ALERT_TYPE.FAIL,
              text: 'general.text_snackbar_error_unexpected_error',
            }),
            handleSessionTimeout(err)
          );
        }),
        takeUntil(action$.pipe(filter(cancelUploadAttachment.match)))
      );
    })
  );

const deleteAttachmentEpic: Epic = action$ =>
  action$.pipe(
    filter(deleteAttachment.match),
    mergeMap(({ payload: attachment, meta: { callbacks } }) => {
      const { success, fail, complete } = callbacks;
      const attachmentId = attachment.id;

      // Skip if the attachment is not created in database
      if (!attachmentId) {
        success?.();
        complete?.();
        return EMPTY;
      }

      return from(asyncDeleteAttachment(attachmentId)).pipe(
        mergeMap(() => {
          success?.();
          complete?.();
          return EMPTY;
        }),
        catchError(err => {
          fail?.(err);
          complete?.(undefined, err);
          return of(
            pushAlert({
              type: ALERT_TYPE.FAIL,
              text: 'general.text_snackbar_error_unexpected_error',
            }),
            handleSessionTimeout(err)
          );
        })
      );
    })
  );

export default [uploadAttachmentEpic, deleteAttachmentEpic];
