import { FC } from 'react';
import styled from 'styled-components';

import svgClose from '@/assets/svg/Close.svg';
import SVG from '@/components/SVG';
import Colors from '@/constants/colors';

const CloseButton = styled.div`
  cursor: pointer;
  height: 2rem;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;

  :hover {
    background: ${Colors.BLACK_004};
    border-radius: 2rem;
  }
`;

interface Props {
  id: string;
  className?: string;
  onClick: () => void;
}

const ModalCloseButton: FC<Props> = ({ id, className, onClick }) => (
  <CloseButton id={id} className={className} onClick={onClick}>
    <SVG src={svgClose} />
  </CloseButton>
);

export default ModalCloseButton;
