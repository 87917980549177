export enum ALERT_TYPE {
  SUCCESS = 'success',
  FAIL = 'fail',
  NEGATIVE_SUCCESS = 'negative-success',
}

export const ALERT_REASON = {
  UPDATE_SUCCESS: 'update_success',
  UPDATE_FAILED: 'update_failed',
};
