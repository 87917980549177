export default {
  BLACK_000: 'rgba(0, 0, 0, 0)',
  BLACK_002: 'rgba(0, 0, 0, 0.02)',
  BLACK_004: 'var(--ixt-disabled-black-a04)',
  BLACK_009: 'var(--ixt-black-a09)',
  BLACK_015: 'var(--ixt-black-a15)',
  BLACK_025: 'var(--ixt-black-a25)',
  BLACK_045: 'var(--ixt-black-a45)',
  BLACK_065: 'var(--ixt-black-a65)',
  BLACK_085: 'rgba(0, 0, 0, 0.85)',

  WHITE_004: 'rgba(255, 255, 255, 0.04)',
  WHITE_009: 'rgba(255, 255, 255, 0.09)',
  WHITE_015: 'rgba(255, 255, 255, 0.15)',
  WHITE_025: 'rgba(255, 255, 255, 0.25)',
  WHITE_045: 'rgba(255, 255, 255, 0.45)',
  WHITE_065: 'var(--ixt-white-a65)',
  WHITE_080: 'rgba(255, 255, 255, 0.80)',
  WHITE_085: 'rgba(255, 255, 255, 0.85)',
  WHITE_100: 'var(--ixt-white)',

  GRAY_100: 'var(--ixt-gray-100)',
  GRAY_200: 'var(--ixt-gray-200)',
  GRAY_300: 'var(--ixt-gray-300)',
  GRAY_400: 'var(--ixt-gray-400)',
  GRAY_500: 'var(--ixt-gray-500)',
  GRAY_600: 'var(--ixt-gray-600)',
  GRAY_800: 'var(--ixt-gray-800)',

  PRIMARY_050: '#E0F7F6',
  PRIMARY_100: '#B3EBE8',
  PRIMARY_200: '#80DED9',
  PRIMARY_300: '#4DD0C9',
  PRIMARY_300_ALPHA_20: 'rgba(77, 208, 201, 0.2)',
  PRIMARY_400: '#26C6BE',
  PRIMARY_500: '#00BCB2',
  PRIMARY_500_ALPHA_10: 'rgba(0, 188, 178, 0.1)',
  PRIMARY_500_ALPHA_30: 'rgba(0, 188, 178, 0.3)',
  PRIMARY_500_ALPHA_06: 'rgba(0, 188, 178, 0.06)',

  PRIMARY_600: '#00A599',
  PRIMARY_700: '#00978A',
  PRIMARY_800: '#008070',
  PRIMARY_900: '#006F61',

  SECONDARY_050: '#FEF8E0',
  SECONDARY_100: '#FDEDB3',
  SECONDARY_200: '#FCE180',
  SECONDARY_300: '#FAD44D',
  SECONDARY_400: '#F9CB26',
  SECONDARY_500: 'var(--ixt-yellow-500)',
  SECONDARY_500_ALPHA_10: 'rgba(248, 194, 0, 0.1)',
  SECONDARY_600: '#F7BC00',
  SECONDARY_700: '#F6B400',
  SECONDARY_800: '#F5AC00',
  SECONDARY_900: '#F39F00',

  ERROR_050: '#FFEBEE',
  ERROR_100: '#FFCDD2',
  ERROR_200: '#EF9A9A',
  ERROR_300: '#E57373',
  ERROR_400: '#EF5350',
  ERROR_500: '#F44336',
  ERROR_600: '#E53935',
  ERROR_600_ALPHA_10: 'rgba(229, 57, 53, 0.1)',
  ERROR_600_ALPHA_30: 'rgba(229, 57, 53, 0.3)',
  ERROR_700: '#D32F2F',
  ERROR_800: '#C62828',
  ERROR_900: '#C62828',

  AUXILIARY_A_100: '#1890FF',
  AUXILIARY_A_100_ALPHA_20: 'rgba(24, 144, 255, 0.2)',
  AUXILIARY_B_100: '#718CE3', // ixt-purple-100
  AUXILIARY_B_100_ALPHA_20: 'rgba(113, 140, 227, 0.2)',

  // semantic colors
  SIDEBAR_BG: 'var(--ixt-sidebar-bg)',
  SIDEBAR_ITEM_HOVER: 'var(--ixt-sidebar-item-hover)',
  TABLE_ROW_BG: 'var(--ixt-table-row-bg)', // need rename
  INPUT_BG: 'var(--ixt-input-bg)',
};
