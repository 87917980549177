import { ComponentProps, FC } from 'react';
import styled from 'styled-components';
import { TFunction } from 'next-i18next';

import DefaultModal from '@/components/modal/DefaultModal';
import ContainedButton, { CONTAINED_BUTTON_MODE } from '@/patterns/button/ContainedButton';

const CloseButton = styled(ContainedButton)`
  width: 7.5rem;
  padding: 0.75rem 0;
`;

interface Props extends Omit<ComponentProps<typeof DefaultModal>, 'title' | 'renderContent' | 'renderButtons'> {
  t: TFunction;
  i18nKeyTitle: string;
  i18nKeyDescription: string;
  i18nDescriptionValues?: Record<string, unknown>;
  i18nKeyCloseButtonText: string;
  buttonMode?: CONTAINED_BUTTON_MODE;
}

const AlertModal: FC<Props> = ({
  t,
  i18nKeyTitle,
  i18nKeyDescription,
  i18nDescriptionValues,
  i18nKeyCloseButtonText,
  buttonMode = CONTAINED_BUTTON_MODE.ERROR,
  ...rest
}) => {
  const renderContent = () => t(i18nKeyDescription, i18nDescriptionValues);

  const renderButtons = () => [
    <CloseButton key="close" id="button-alert-modal-close" mode={buttonMode} onClick={rest.onClose}>
      {t(i18nKeyCloseButtonText)}
    </CloseButton>,
  ];

  return <DefaultModal {...rest} title={t(i18nKeyTitle)} renderContent={renderContent} renderButtons={renderButtons} />;
};

export default AlertModal;
