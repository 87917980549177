const ZIndices = {
  UnderTheSea: -1,
  SeaSurface: 1,
  StickyHeader: 100,
  SubSidebar: 150,
  SidebarOverlay: 200,
  Sidebar: 250,
  Drawer: 250,
  DropdownMenu: 300,
  Modal: 400,
  Tooltip: 500,
  Loading: 550,
  Alert: 600,
};

export default ZIndices;
