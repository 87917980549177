import { combineReducers, createReducer } from '@reduxjs/toolkit';

import { REMARK_ATTACHMENT_STATE } from '@/constants/remark';
import { IncrementalId } from '@/interfaces/id';

import {
  cancelAllUploadRemarkAttachment,
  deleteUploadRemarkAttachment,
  retryUploadRemarkAttachment,
  startUploadRemarkAttachment,
  uploadRemarkAttachment,
  uploadRemarkAttachmentError,
  uploadRemarkAttachmentSuccess,
} from './actions';
import { IRemarkProviderState } from './interfaces';

const initialState = {};

const uploadTaskMapReducer = createReducer<IRemarkProviderState['uploadTaskMap']>(initialState, builder => {
  builder
    .addCase(uploadRemarkAttachment, (state, { payload }) => {
      const { id, data } = payload;
      state[id] = {
        name: data.name,
        state: REMARK_ATTACHMENT_STATE.PENDING,
        data,
      };
    })
    .addCase(startUploadRemarkAttachment, (state, { payload }) => {
      const { id } = payload;
      if (state[id]) {
        state[id].state = REMARK_ATTACHMENT_STATE.UPLOADING;
      }
    })
    .addCase(retryUploadRemarkAttachment, (state, { payload }) => {
      const { id } = payload;
      if (state[id]) {
        state[id].state = REMARK_ATTACHMENT_STATE.PENDING;
      }
    })
    .addCase(deleteUploadRemarkAttachment, (state, { payload }) => {
      const { id } = payload;
      delete state[id];
    })
    .addCase(uploadRemarkAttachmentSuccess, (state, { payload }) => {
      const { id, data } = payload;
      if (state[id]) {
        state[id].id = data.id as IncrementalId;
        state[id].state = REMARK_ATTACHMENT_STATE.UPLOAD_SUCCESS;
      }
    })
    .addCase(uploadRemarkAttachmentError, (state, { payload }) => {
      const { id } = payload;
      if (state[id]) {
        state[id].state = REMARK_ATTACHMENT_STATE.UPLOAD_FAILED;
      }
    })
    .addCase(cancelAllUploadRemarkAttachment, () => initialState);
});

export default combineReducers({
  uploadTaskMap: uploadTaskMapReducer,
});
