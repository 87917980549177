import { has } from 'lodash';

// eslint-disable-next-line max-classes-per-file
export class ApiValidationError extends Error {
  private field: string;

  public constructor(field: string, message?: string) {
    super(message);
    this.name = 'ApiValidationError';
    this.field = field;
  }

  public get fieldName() {
    return this.field;
  }
}

// eslint-disable-next-line max-classes-per-file
export class ApiValidationErrors extends Error {
  private fields: string[];

  private messages: string[];

  public constructor(fields: string[], messages: string[]) {
    super();
    this.name = 'ApiValidationErrors';
    this.fields = fields;
    this.messages = messages;
  }

  public get fieldName() {
    return this.fields;
  }

  public get messagesValue() {
    return this.messages;
  }
}

interface IApiResponseClientError {
  status: number;
  message: string;
  data: {
    error_codes: number[];
    lineno: string;
    offset: string;
    text: string;
    invalid_accesses: {
      access_path: string;
      method: string;
    }[];
  };
}

const hasErrorCodes = (value: any): value is Pick<IApiResponseClientError, 'data'> => {
  return has(value, 'data.error_codes');
};

/**
 * API response error for 4xx error (should have status code defined in '@/constants/apiStatusCode')
 */
export class ApiResponseClientError<
  TRawResponse extends Pick<IApiResponseClientError, 'status'> = IApiResponseClientError
> extends Error {
  public rawResponse: TRawResponse;

  /**
   * @deprecated please migrate to use `errorCodes` instead
   * remain until code to be removed
   */
  public code: number;

  /*
   * status is an error code for api error, tells the category of the error
   * error code can reference @/constants/apiStatusCode and @/utils/pythonCode
   */
  public status: number;

  /*
   * similar to status, but errorCodes give more information like which validation is fail.
   * in order to provide more precise error message
   */
  public errorCodes: number[];

  public constructor(rawResponse: TRawResponse, message?: string) {
    super(message);
    this.name = 'ApiResponseClientError';
    this.rawResponse = rawResponse;
    this.code = rawResponse.status;
    this.status = rawResponse.status;
    // eslint-disable-next-line no-nested-ternary
    this.errorCodes = hasErrorCodes(rawResponse)
      ? rawResponse.data.error_codes
      : rawResponse.status
      ? [rawResponse.status]
      : [];
  }

  public hasStatusCode(statusCode: number) {
    return this.status === statusCode;
  }

  public hasErrorCodes(errorCodes: number[]) {
    return errorCodes.every(code => this.errorCodes.includes(code));
  }
}

const hasMessage = (value: unknown): value is { message: string } => {
  return !!value && typeof value === 'object' && 'message' in value;
};

/**
 * API response error for 5xx error or other unknown error response
 */
export class ApiResponseServerError<TRawResponse = unknown> extends Error {
  public rawResponse: TRawResponse;

  public constructor(rawResponse: TRawResponse, message?: string) {
    super(hasMessage(rawResponse) ? rawResponse.message : message);
    this.name = 'ApiResponseServerError';
    this.rawResponse = rawResponse;
  }
}

export type ApiResponseError = ApiResponseClientError | ApiResponseServerError;

// TODO: throw ApiAuthError for IXT Auth Api errors instead of `ApiResponseClientError`
export class ApiAuthError extends Error {
  httpStatus: number;

  code: number;

  constructor(httpStatus: number, code: number, message?: string) {
    super(message);
    this.name = 'ApiAuthError';
    this.httpStatus = httpStatus;
    this.code = code;
  }
}
