import { css } from 'styled-components';

const buttonCssMixin = css<{ disabled?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 0.5rem;
  border-radius: 0.5rem;
  user-select: none;
  outline: 0;
  :focus {
    outline: 0;
  }
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;

export default buttonCssMixin;
