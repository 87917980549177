import { Subject } from 'rxjs';

/**
 * A function that should do navigation when invoked.
 */
type PendingNavigation = () => void;

const navigationGuard = new Set<string>();

export const addNavigationGuard = (key: string) => {
  if (!navigationGuard.has(key)) {
    navigationGuard.add(key);
  }
};

export const removeNavigationGuard = (key: string) => {
  if (navigationGuard.has(key)) {
    navigationGuard.delete(key);
  }
};

export const checkIsNavigationAllowed = () => navigationGuard.size === 0;

const navigationGuardNotification = new Subject();
const pendingNavigationList: PendingNavigation[] = [];

export const subscribeNavigationGuardNotification = (cb: () => void) => {
  const subscription = navigationGuardNotification.subscribe(cb);
  return () => {
    subscription.unsubscribe();
  };
};

export const suspendNavigation = (navigation: PendingNavigation) => {
  navigationGuardNotification.next();
  pendingNavigationList.push(navigation);
};

export const continueLastPendingNavigation = () => {
  pendingNavigationList.pop()?.();
};

export const abortLastPendingNavigation = () => {
  pendingNavigationList.pop();
};
