/* __base__ will be replaced to specific platform */
import BASE_COLORS from './__base__/color';
import BASE_META, { IDENTIFIER } from './__base__/meta';
import BASE_NAMESPACES from './__base__/namespace';

export default {
  identifier: IDENTIFIER,
  meta: BASE_META,
  colors: BASE_COLORS,
  namespaces: BASE_NAMESPACES,
};
