import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AjaxError } from 'rxjs/ajax';
import { tap } from 'rxjs/operators';

import { ApiResponseClientError } from '@/constants/errors';

export type ActionCallbacks<R = any, E = Error> = {
  success?: (res: R) => void;
  fail?: (err: E) => void;
  complete?: (res?: R, error?: E) => void;
};

type CallbacksPrepareAction<P, R = void, E = Error> = (
  payload: P,
  callbacks: ActionCallbacks<R, E>
) => {
  payload: P;
  meta: { callbacks: ActionCallbacks<R, E> };
};

/**
 * Create an action creator with `success`, `fail` and `complete` callbacks
 */
export function createCallbacksAction<P, R = void, E = Error>(actionType: string) {
  return createAction<CallbacksPrepareAction<P, R, E>>(actionType, (payload, callbacks) => ({
    payload,
    meta: { callbacks },
  }));
}

/**
 * Create an action creator with `success`, `fail` and `complete` callbacks but wrapped in an async thunk.
 *
 * Async thunk dispatch the action `success` and `fail` callbacks under the hood and wrap the callbacks into a promise
 * so that we can await the dispatched action (ie.`await dispatch(asyncThunk(...));`) to handle UI operations.
 *
 * The created callbacks action should be used with `createAjaxCallbacksHandler` for Epics
 * to resolve or reject the promise that the async thunk returned.
 *
 * Sample Usage:
 *
 * ```
 * // provider.ts
 * export const [actionCreator, actionCreatorThunk] = createAwaitableActions('some-action-type');
 *
 * const someEpic = action$ =>
 *   action$.pipe(
 *     filter(actionCreator.match),
 *     switchMap(({ meta: { callbacks } }) =>
 *       ajax(...).pipe(
 *         createAjaxCallbacksHandler(callbacks),
 *         ...
 *       )
 *     )
 *   );
 *
 * // inside component body
 * const dispatch = useDispatch<RootDispatch>(); // `RootDispatch` contains type info for the thunks
 *
 * const someFn = async () => {
 *   await dispatch(actionCreatorThunk(...));
 * };
 * ```
 *
 */
export function createAwaitableActions<P, R = void, E = Error>(actionType: string) {
  const callbacksAction = createCallbacksAction<P, R, E>(actionType);
  return <const>[
    callbacksAction,
    createAsyncThunk<R, P>(
      actionType,
      (payload, thunkApi) =>
        new Promise((success, fail) => {
          thunkApi.dispatch(callbacksAction(payload, { success, fail }));
        })
    ),
  ];
}

export function createAjaxCallbacksHandler<T>({ success, complete, fail }: ActionCallbacks) {
  return tap<T>(
    res => {
      success?.(res);
      complete?.();
    },
    error => {
      const err =
        error instanceof AjaxError
          ? new ApiResponseClientError(error.response, error.response.message ?? '')
          : new Error(error?.message ?? '');
      // code can only be string type
      // Ref: https://redux-toolkit.js.org/api/createAsyncThunk#handling-thunk-errors

      fail?.(err);
      complete?.(undefined, err);
    }
  );
}
