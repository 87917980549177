enum SCHEMA_LDAP_AUTHENTICATION {
  ID = 'id',
  IS_ACTIVE = 'is_active',
  CREATE_TIME = 'create_time',
  NAME = 'name',
  HOSTNAME = 'hostname',
  PORT = 'port',
  DOMAIN_BASE = 'domain_base',
  USER_IDENTIFIER = 'user_identifier',
  ROLE = 'role',
}

export default SCHEMA_LDAP_AUTHENTICATION;
