/**
 * Create the store with dynamic reducers
 */

import { getDefaultMiddleware } from '@reduxjs/toolkit';
import { enableMapSet } from 'immer';
import { createWrapper } from 'next-redux-wrapper';
import { applyMiddleware } from 'redux';
import { createStore } from 'redux-dynamic-modules';
import { getObservableExtension } from 'redux-dynamic-modules-observable';

import { setStore } from '@/utils/getStore';

import epics from './epics';
import reducers from './reducers';

enableMapSet();

const appModule = {
  id: 'app',
  epics,
  reducerMap: reducers,
};

const configureStore = () => {
  const store = createStore(
    {
      initialState: {},
      extensions: [getObservableExtension()],
      enhancers: [
        applyMiddleware(
          ...getDefaultMiddleware({
            thunk: true,
            serializableCheck: {
              ignoreState: true,
              ignoredActionPaths: ['meta.callbacks'],
            },
          })
        ),
      ],
    },
    appModule
  );

  setStore(store);
  return store;
};

export default createWrapper(configureStore);
