import {
  ICreateLdapLoginRequest,
  ICreateLoginRequest,
  ICreateLoginResponse,
  ICreateUpdatePasswordRequest,
  IUpdateMeRequest,
  IUser,
} from '@/interfaces/od-api';

import { ixtApiGet, ixtApiPatch, ixtApiPost } from './utils';

export const apiLogin = (request: ICreateLoginRequest) =>
  ixtApiPost<ICreateLoginResponse['data']>({
    url: '/login',
    request,
  });

export const apiLdapLogin = (request: ICreateLdapLoginRequest) =>
  ixtApiPost<ICreateLoginResponse['data']>({
    url: '/ldap-login',
    request,
  });

export const apiUpdatePassword = (request: ICreateUpdatePasswordRequest) =>
  ixtApiPost({
    url: '/update-password',
    request,
  });

export const apiGetMe = () =>
  ixtApiGet<IUser>({
    url: '/me',
  });

export const apiUpdateMe = (request: IUpdateMeRequest) =>
  ixtApiPatch({
    url: '/me',
    request,
  });
