import styled from 'styled-components';

import Colors from '@/constants/colors';

const duration = 1.4;
const radius = 16;
const offset = Math.floor(Math.PI * radius * 2); // The circumference of the circle (2 * π * radius)
const strokeWidth = 4;
const size = radius * 2 + strokeWidth + 1;

const Root = styled.svg`
  animation: rotate ${duration}s linear infinite;

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Circle = styled.circle`
  stroke-dasharray: ${offset}px;
  transform-origin: center;
  animation: dash ${duration}s ease-in-out infinite, colors ${duration * 3}s ease-in-out infinite;

  @keyframes dash {
    0% {
      stroke-dashoffset: ${offset}px;
      transform: rotate(0deg);
    }
    50% {
      stroke-dashoffset: ${offset / 4}px;
      transform: rotate(90deg);
    }
    100% {
      stroke-dashoffset: ${offset}px;
      transform: rotate(360deg);
    }
  }

  @keyframes colors {
    0% {
      stroke: ${Colors.SECONDARY_500};
    }

    33% {
      stroke: ${Colors.PRIMARY_500};
    }

    66% {
      stroke: ${Colors.AUXILIARY_B_100};
    }

    100% {
      stroke: ${Colors.SECONDARY_500};
    }
  }
`;

function SpinnerLoading() {
  return (
    <Root width={size} height={size} viewBox={`0 0 ${size - 1} ${size - 1}`} xmlns="http://www.w3.org/2000/svg">
      <Circle
        fill="none"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        cx={radius + strokeWidth / 2}
        cy={radius + strokeWidth / 2}
        r={radius}
      />
    </Root>
  );
}

export default SpinnerLoading;
