import { FC } from 'react';
import { useTranslation } from 'next-i18next';

import Namespace from '@/constants/namespace';
import PageError from '@/modules/general/PageError';
import Router from '@/utils/nextRouter';

const ClientError: FC = () => {
  const { t } = useTranslation([Namespace.ODP_GENERAL]);

  return (
    <PageError
      errorCodeText={t('general.text_client_error')}
      title={t('general.text_unknown')}
      description={t('general.text_error_on_client')}
      buttonText={t('general.action_bring_me_back')}
      onButtonClick={() => Router.back()}
    />
  );
};

export default ClientError;
