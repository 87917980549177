export const MODULE_ADMIN = 'admin';
export const MODULE_APPLICATION = 'application';
export const MODULE_CAMPAIGN = 'campaign';
export const MODULE_CMS = 'cms';
export const MODULE_EVENT = 'event';
export const MODULE_FORM = 'form';
export const MODULE_MEMBER = 'member';
export const MODULE_PARTNER = 'partner';
export const MODULE_POLICY = 'policy';
export const MODULE_PRODUCT = 'product';
export const MODULE_REFERENCE_TABLE = 'reference_table';
export const MODULE_SYSTEM = 'system';
export const MODULE_USER = 'user';

export enum MODULE {
  PRODUCT = 'product',
  APPLICATION = 'application',
  POLICY = 'policy',
  MEMBER = 'member',
  CAMPAIGN = 'campaign',
}

export enum SERVICE {
  CORE = 'core',
  LAUNCHPAD = 'launchpad',
  RESOURCE = 'resource',
  SYSTEM = 'system',
  PRM = 'prm',
  CLAIM = 'claim',
  UTILITY = 'utility',
}

export const ALL_PERMISSION_MODULES = [
  MODULE_ADMIN,
  MODULE_APPLICATION,
  MODULE_CAMPAIGN,
  MODULE_CMS,
  MODULE_EVENT,
  MODULE_FORM,
  MODULE_MEMBER,
  MODULE_PARTNER,
  MODULE_POLICY,
  MODULE_PRODUCT,
  MODULE_REFERENCE_TABLE,
  MODULE_SYSTEM,
  MODULE_USER,
];

export enum PERMISSION {
  // Sorted by alphabet

  // Admin Module
  ADMIN__DELETE_ADMIN_SETTINGS = 'admin.delete_admin_settings',
  ADMIN__EDIT_ADMIN_SETTINGS = 'admin.edit_admin_settings',
  ADMIN__VIEW_ADMIN_SETTINGS = 'admin.view_admin_settings',
  ADMIN__VIEW_DATA_MASKING_SETTINGS = 'admin.view_data_masking_settings',
  ADMIN__EDIT_DATA_MASKING_SETTINGS = 'admin.edit_data_masking_settings',

  // Application Module
  APPLICATION__EDIT_APPLICATION = 'application.edit_application',
  APPLICATION__REVIEW_APPLICATION = 'application.review_application',
  APPLICATION__UNDERWRITE_APPLICATION = 'application.underwrite_application',
  APPLICATION__VIEW_APPLICATION = 'application.view_application',

  // Campaign Module
  CAMPAIGN__EDIT_CAMPAIGN = 'campaign.edit_campaign',
  CAMPAIGN__REVIEW_CAMPAIGN = 'campaign.review_campaign',
  CAMPAIGN__VIEW_CAMPAIGN = 'campaign.view_campaign',

  // CMS Module
  CMS__DELETE_FLOW = 'cms.delete_flow',
  CMS__EDIT_FLOW = 'cms.edit_flow',
  CMS__REVIEW_FLOW = 'cms.review_flow',
  // TODO: replace with the commented line below after next cms release
  CMS__VIEW_FLOW = 'cms.launchpad_view_flow',
  // CMS__VIEW_FLOW = 'cms.view_flow',

  // Event Module
  EVENT__DELETE_EVENT_SETTINGS = 'event.delete_event_settings',
  EVENT__EDIT_EVENT_SETTINGS = 'event.edit_event_settings',
  EVENT__VIEW_EVENT_SETTINGS = 'event.view_event_settings',

  // FORM Module
  FORM__VIEW_FORM = 'form.view_form',
  FORM__EDIT_FORM = 'form.edit_form',
  FORM__DELETE_FORM = 'form.delete_form',

  // Member Module
  MEMBER__CHANGE_MEMBER_STATUS = 'member.change_member_status',
  MEMBER__EDIT_MEMBER = 'member.edit_member',
  MEMBER__REVIEW_CHANGE_REQUEST = 'member.review_member_change_request',
  MEMBER__VIEW_MEMBER = 'member.view_member',

  // Partner (PRM)
  PARTNER__EDIT_PARTNER = 'partner.edit_partner',
  PARTNER__VIEW_PARTNER = 'partner.view_partner',

  // Policy Module
  POLICY__CHANGE_POLICY_STATUS = 'policy.change_policy_status',
  POLICY__EDIT_POLICY = 'policy.edit_policy',
  POLICY__REVIEW_CHANGE_REQUEST = 'policy.review_policy_change_request',
  POLICY__VIEW_POLICY = 'policy.view_policy',

  // Product Module
  PRODUCT__DELETE_PRODUCT = 'product.delete_product',
  PRODUCT__EDIT_LIBRARY = 'product.edit_library',
  PRODUCT__EDIT_PRODUCT = 'product.edit_product',
  PRODUCT__REVIEW_LIBRARY = 'product.review_library',
  PRODUCT__REVIEW_PRODUCT = 'product.review_product',
  PRODUCT__VIEW_LIBRARY = 'product.view_library',
  PRODUCT__VIEW_PRODUCT = 'product.view_product',

  // Reference Table
  REFERENCE_TABLE__DELETE_REFERENCE_TABLE = 'reference_table.delete_reference_table',
  REFERENCE_TABLE__EDIT_REFERENCE_TABLE = 'reference_table.edit_reference_table',
  REFERENCE_TABLE__REVIEW_REFERENCE_TABLE = 'reference_table.review_reference_table',
  REFERENCE_TABLE__VIEW_REFERENCE_TABLE = 'reference_table.view_reference_table',

  // System Module
  SYSTEM__VIEW_ACTIVITY_LOG = 'system.view_system_activity_log',

  // User Module
  USER__CHANGE_USER_STATUS = 'user.change_internal_user_status',
  USER__DELETE_USER = 'user.delete_internal_user',
  USER__EDIT_USER = 'user.edit_internal_user',
  USER__VIEW_USER = 'user.view_internal_user',

  // Currently Hidden
  // Network Module
  NETWORK__EDIT_PROVIDER = 'network.edit_provider',
  NETWORK__VIEW_PROVIDER = 'network.view_provider',
  NETWORK__CREATE_NETWORK = 'network.edit_network',
  NETWORK__DELETE_NETWORK = 'network.edit_network',
  NETWORK__EDIT_NETWORK = 'network.edit_network',
  NETWORK__VIEW_NETWORK = 'network.view_network',
}
