import { StatusCodes } from 'http-status-codes';
import Cookies from 'js-cookie';

import { COOKIE_ACCESS_TOKEN } from '@/constants/auth';
import { IXT_API_URL } from '@/services/OneDegree/constants';

import { ApiResponseClientError, ApiResponseError } from '../constants/error';
import downloadBlob from './downloadBlob';

export const ixtDownloadFn = async ({
  path,
  openNewTab = false,
  fileName,
  headers,
  version = 1,
  searchParams,
}: {
  path: string;
  openNewTab?: boolean;
  fileName: string;
  version?: 1 | 2;
  headers?: Record<string, string>;
  searchParams?: Record<string, string>;
}) => {
  const searchParamsString = new URLSearchParams(searchParams).toString();
  const url = new URL(`/v${version}${path}${searchParamsString ? `?${searchParamsString}` : ''}`, IXT_API_URL);
  const accessToken = Cookies.get(COOKIE_ACCESS_TOKEN);
  const response = await fetch(url.toString(), {
    method: 'GET',
    headers: {
      ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
      ...headers,
    },
  });

  if (response.ok) {
    const blob = await response.blob();
    if (openNewTab) {
      /**
       * NOTE: (CORE-4576)
       * Since IXT-API returns a blob with content-type: application/octet-stream,
       * we need to convert the blob to a file with the correct content-type to open it in a new tab.
       * Otherwise, the browser will download the file instead of opening it in a new tab.
       * We may check mime-type in the future to determine the content-type of the file.
       * ref: https://www.npmjs.com/package/file-type
       */
      const ext = fileName.slice(fileName.lastIndexOf('.') + 1);
      switch (ext) {
        case 'pdf':
          window.open(URL.createObjectURL(new File([blob], fileName, { type: 'application/pdf' })));
          return;
        case 'jpg':
        case 'jpeg':
          window.open(URL.createObjectURL(new File([blob], fileName, { type: 'image/jpeg' })));
          return;
        case 'png':
          window.open(URL.createObjectURL(new File([blob], fileName, { type: 'image/png' })));
          return;
        default:
          break;
      }
    }

    downloadBlob(blob, fileName);
  }
};

export const ixtQueryFn = async <TResponseData>({
  path,
  searchParams,
  headers,
  version = 1,
}: {
  path: string;
  searchParams?: Record<string, string | string[]>;
  headers?: Record<string, string>;
  version?: 1 | 2;
}): Promise<TResponseData> => {
  const accessToken = Cookies.get(COOKIE_ACCESS_TOKEN);
  const options: RequestInit = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
      ...headers,
    },
  };

  const params = new URLSearchParams();
  if (searchParams) {
    Object.entries(searchParams).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach(v => params.append(key, v));
      } else {
        params.append(key, value);
      }
    });
  }
  const searchParamsString = params.toString();

  const url = new URL(`/v${version}${path}${searchParamsString ? `?${searchParamsString}` : ''}`, IXT_API_URL);

  const resp = await fetch(url.toString(), options);

  if (resp.ok) {
    if (resp.status === StatusCodes.NO_CONTENT) {
      return Promise.resolve() as unknown as Promise<TResponseData>;
    }
    return resp.json();
  }

  const data = await resp.json();

  return Promise.reject(new ApiResponseError(resp.status, data));
};

const getMutationRequestBody = <TRequest>(request: TRequest): BodyInit | undefined => {
  if (request) {
    return request instanceof Blob || request instanceof FormData ? request : JSON.stringify(request);
  }
  return undefined;
};

export const ixtMutationFn = async <TRequest = Record<string, unknown> | FormData | Blob, TResponseData = void>({
  path,
  method = 'POST',
  request,
  headers,
  version = 1,
  searchParams,
}: {
  path: string;
  method?: RequestInit['method'];
  request?: TRequest;
  headers?: Record<string, string>;
  version?: 1 | 2;
  searchParams?: Record<string, string>;
}): Promise<TResponseData> => {
  const searchParamsString = new URLSearchParams(searchParams).toString();
  const url = new URL(`/v${version}${path}${searchParamsString ? `?${searchParamsString}` : ''}`, IXT_API_URL);
  const accessToken = Cookies.get(COOKIE_ACCESS_TOKEN);
  const options: RequestInit = {
    method,
    headers: {
      Accept: 'application/json',
      ...(request instanceof Blob || request instanceof FormData ? {} : { 'Content-Type': 'application/json' }),
      ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
      ...headers,
    },
    body: getMutationRequestBody(request),
  };

  const rawResponse = await fetch(url.toString(), options);

  const response: TResponseData & ApiResponseError<{ code?: number }> =
    rawResponse.status === StatusCodes.NO_CONTENT ? rawResponse : await rawResponse.json();
  // TODO: [CORE-4930] revamp error schema (code, error_code, error_codes) to have a consistent error handling
  if (!rawResponse.ok) throw new ApiResponseError(rawResponse.status, response);

  if (response !== null && 'error_code' in response) {
    throw new ApiResponseClientError(response);
  }

  return response;
};
