/* Code generated, DO NOT EDIT */

export enum OD_ENUM_ME_NOTIFICATIONS__TYPE {
  SYSTEM_MAINTENANCE = 'system_maintenance',
  WELCOME = 'welcome',
  MEMBER_CHANGE_REQUEST_SUBMIT = 'member_change_request_submit',
  MEMBER_CHANGE_REQUEST_REJECT = 'member_change_request_reject',
  MEMBER_CHANGE_REQUEST_APPROVE = 'member_change_request_approve',
  MEMBER_CHANGE_REQUEST_WITHDRAW = 'member_change_request_withdraw',
  PRODUCT_LAUNCH = 'product_launch',
  PRODUCT_REVIEW = 'product_review',
  PRODUCT_RETURN_TO_DRAFT = 'product_return_to_draft',
  PRODUCT_APPROVAL = 'product_approval',
  PRODUCT_SHELVE = 'product_shelve',
  QUOTATION_ASSIGN = 'quotation_assign',
  QUOTATION_REVIEW = 'quotation_review',
  QUOTATION_SEND_BACK = 'quotation_send_back',
  QUOTATION_APPROVE = 'quotation_approve',
  CAMPAIGN_SUBMIT = 'campaign_submit',
  CAMPAIGN_SEND_BACK = 'campaign_send_back',
  CAMPAIGN_APPROVE = 'campaign_approve',
  CAMPAIGN_LAUNCH = 'campaign_launch',
  CAMPAIGN_SHELVE = 'campaign_shelve',
  CAMPAIGN_CHANGE_REQUEST_SUBMIT = 'campaign_change_request_submit',
  CAMPAIGN_CHANGE_REQUEST_WITHDRAW = 'campaign_change_request_withdraw',
  CAMPAIGN_CHANGE_REQUEST_APPROVE = 'campaign_change_request_approve',
  CAMPAIGN_CHANGE_REQUEST_REJECT = 'campaign_change_request_reject',
  CLAIM_SUBMIT_ADJUDICATING = 'claim_submit_adjudicating',
  CLAIM_SUBMIT_REVIEW = 'claim_submit_review',
  CLAIM_APPROVE = 'claim_approve',
  CLAIM_SENDBACK_ADJUDICATING = 'claim_sendback_adjudicating',
  CLAIM_ASK_PENDING_INFO = 'claim_ask_pending_info',
  CLAIM_WITHDRAW = 'claim_withdraw',
  CLAIM_SUBMIT_PENDING_INFO = 'claim_submit_pending_info',
  CLAIM_ASSIGN_INTERNAL_USER = 'claim_assign_internal_user',
  POLICY_CHANGE_REQUEST_SUBMIT = 'policy_change_request_submit',
  POLICY_CHANGE_REQUEST_WITHDRAW = 'policy_change_request_withdraw',
  POLICY_CHANGE_REQUEST_REJECT = 'policy_change_request_reject',
  POLICY_CHANGE_REQUEST_APPROVE = 'policy_change_request_approve',
}
