import { getParser } from 'bowser';

let parsedInfo = null;
let platformType = null;
let osName = null;
let browserName = null;

const POPULAR_OS = {
  macos: 1,
  windows: 1,
  android: 1,
  ios: 1,
};

const POPULAR_BROWSER = {
  chrome: 1,
  firefox: 1,
  safari: 1,
  android: 1,
  'microsoft edge': 1,
};

export const getIsHandheld = () => {
  if (parsedInfo) {
    return platformType === 'mobile' || platformType === 'tablet';
  }
};

export const getIsIOS = () => {
  if (parsedInfo) {
    return osName === 'ios';
  }
};

export const getIsAndroid = () => {
  if (parsedInfo) {
    return osName === 'android';
  }
};

export const getIsChrome = () => {
  if (parsedInfo) {
    return browserName === 'chrome';
  }
};

export const getIsFirefox = () => {
  if (parsedInfo) {
    return browserName === 'firefox';
  }
};

export const getDeviceInfo = () => (parsedInfo ? parsedInfo.getResult() : {});

export const isPopularOS = () => !!POPULAR_OS[osName];

export const isPopularBrowser = () => !!POPULAR_BROWSER[browserName];

export const initDeviceInfo = ua => {
  if (parsedInfo) {
    return;
  }

  parsedInfo = getParser(ua);
  browserName = parsedInfo.getBrowserName().toLowerCase();
  osName = parsedInfo.getOSName().toLowerCase();
  platformType = parsedInfo.getPlatformType().toLowerCase();
};
