import { FC, memo } from 'react';
import styled from 'styled-components';

import svgError from '@/assets/svg/404error.svg';
import SVG from '@/components/SVG';
import Colors from '@/constants/colors';
import Typography from '@/constants/typography';
import ContainedButton from '@/patterns/button/ContainedButton';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  width: 100%;
`;

const Icon = styled(SVG)`
  height: 10rem;
  width: 10rem;
  margin-bottom: 1rem;
`;

const ErrorCode = styled.div.attrs({ className: Typography.SUBTITLE_L })`
  color: ${Colors.BLACK_045};
  margin-bottom: 0.5rem;
`;

const Title = styled.h2.attrs({ className: Typography.H2_HEADING })`
  color: ${Colors.BLACK_065};
  margin: 0 0 0.5rem 0;
`;

const Description = styled.div.attrs({ className: Typography.BODY_S })`
  color: ${Colors.BLACK_045};
  padding: 0 2rem;
  margin-bottom: 2rem;
`;

const Button = styled(ContainedButton)`
  height: 2.5rem;
  width: 7.5rem;
`;
interface PageErrorProps {
  svgIcon?: string;
  errorCodeText: string;
  title: string;
  description: string;
  buttonText: string;
  onButtonClick: () => void;
}

const PageError: FC<PageErrorProps> = ({
  svgIcon = svgError,
  errorCodeText = 'Error',
  title = '',
  description = '',
  buttonText,
  onButtonClick,
}) => (
  <Wrapper>
    <Icon src={svgIcon} />
    <ErrorCode>{errorCodeText}</ErrorCode>
    <Title>{title}</Title>
    <Description>{description}</Description>
    <Button id="button" onClick={onButtonClick}>
      {buttonText}
    </Button>
  </Wrapper>
);

export default memo(PageError);
