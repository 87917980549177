import styled from 'styled-components';

import Colors from '@/constants/colors';
import Typography from '@/constants/typography';

const ModalTitle = styled.h4.attrs({ className: Typography.H4_HEADING })`
  color: ${Colors.BLACK_065};
  padding: 2.5rem 2.5rem 0.5rem;
  margin: 0;
`;

export default ModalTitle;
