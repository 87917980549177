/**
 * Simulate link click to download a file (in blob) to local
 *
 * @param {Blob} blob
 * @param {string=} fileName
 */
function downloadBlob(blob: Blob, fileName?: string): void {
  const a = document.createElement('a');
  const url = URL.createObjectURL(blob);
  a.href = url;
  a.download = fileName || 'blob';
  a.click();
  URL.revokeObjectURL(url);
}

export default downloadBlob;
