import styled from 'styled-components';

import Colors from '@/constants/colors';

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1.5rem 2.5rem;
  border-top: 0.0625rem solid ${Colors.BLACK_009};
`;

export default ModalFooter;
