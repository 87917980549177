import { IApiGetResourcesMethod } from '@/interfaces/api';
import {
  IRetrieveCallingCode,
  IRetrieveCountry,
  IRetrieveCurrency,
  IRetrieveLocale,
  IRetrieveMarket,
  IRetrievePlatform,
  IRetrieveTimezone,
} from '@/interfaces/od-api';

import { ixtApiGetResources } from './utils';

export const apiGetCallingCodes: IApiGetResourcesMethod<IRetrieveCallingCode[]> = request =>
  ixtApiGetResources({
    url: '/calling-codes',
    request,
  });

export const apiGetCountries: IApiGetResourcesMethod<IRetrieveCountry[]> = request =>
  ixtApiGetResources({
    url: '/countries',
    request,
  });

export const apiGetCurrencies: IApiGetResourcesMethod<IRetrieveCurrency[]> = request =>
  ixtApiGetResources({
    url: '/currencies',
    request,
  });

export const apiGetMarkets: IApiGetResourcesMethod<IRetrieveMarket[]> = request =>
  ixtApiGetResources({
    url: '/markets',
    request,
  });

export const apiGetTimezone: IApiGetResourcesMethod<IRetrieveTimezone[]> = request =>
  ixtApiGetResources({
    url: '/timezones',
    request,
  });

export const apiGetPlatform: IApiGetResourcesMethod<IRetrievePlatform[]> = request =>
  ixtApiGetResources({
    url: '/platforms',
    request,
  });

export const apiGetLocales: IApiGetResourcesMethod<IRetrieveLocale[]> = request =>
  ixtApiGetResources({
    url: '/locales',
    request,
  });
