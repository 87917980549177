import { stringify } from 'query-string';
import { catchError, map, retry } from 'rxjs/operators';
import { request as ajax } from 'universal-rxjs-ajax';

import { IApiMethod } from '@/interfaces/api';
import { processApiError } from '@/services/Sentry';

export const apiGet: IApiMethod<Record<string, any>> = ({
  baseUrl = '',
  url = '',
  request,
  headers = {},
  retries = 1,
  withCredentials,
  responseType,
}) =>
  ajax({
    url: `${baseUrl}${url}${request ? `?${stringify(request)}` : ''}`,
    headers: {
      Accept: 'application/json',
      ...headers,
    },
    crossDomain: true,
    withCredentials,
    ...(responseType ? { responseType } : {}),
  }).pipe(
    retry(retries),
    map(e => e.response),
    catchError(processApiError)
  );

export const apiPost: IApiMethod<Record<string, any> | FormData | Blob> = ({
  baseUrl = '',
  url = '',
  request = {},
  headers = {},
  withCredentials,
}) =>
  ajax({
    url: `${baseUrl}${url}`,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      ...(request instanceof FormData ? {} : { 'Content-Type': 'application/json' }),
      nonce: Date.now(),
      ...headers,
    },
    body: request instanceof Blob || request instanceof FormData ? request : JSON.stringify(request),
    crossDomain: true,
    withCredentials,
  }).pipe(
    map(e => e.response),
    catchError(processApiError)
  );

export const apiPut: IApiMethod<Record<string, any>> = ({
  baseUrl = '',
  url = '',
  request = {},
  headers = {},
  withCredentials,
}) =>
  ajax({
    url: `${baseUrl}${url}`,
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      nonce: Date.now(),
      ...headers,
    },
    body: JSON.stringify(request),
    crossDomain: true,
    withCredentials,
  }).pipe(
    map(e => e.response),
    catchError(processApiError)
  );

export const apiPatch: IApiMethod<Record<string, any>> = ({
  baseUrl = '',
  url = '',
  request = {},
  headers = {},
  withCredentials,
}) =>
  ajax({
    url: `${baseUrl}${url}`,
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      nonce: Date.now(),
      ...headers,
    },
    body: JSON.stringify(request),
    crossDomain: true,
    withCredentials,
  }).pipe(
    map(e => e.response),
    catchError(processApiError)
  );

export const apiDelete: IApiMethod<void> = ({ baseUrl = '', url = '', headers = {}, withCredentials }) =>
  ajax({
    url: `${baseUrl}${url}`,
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      nonce: Date.now(),
      ...headers,
    },
    crossDomain: true,
    withCredentials,
  }).pipe(
    map(e => e.response),
    catchError(processApiError)
  );
