import isNumber from 'lodash/isNumber';
import { catchError, map } from 'rxjs/operators';
import { v4 as uuidV4 } from 'uuid';

import { ACCESS_TOKEN_ERROR, STATUS_SUCCESS } from '@/constants/apiStatusCode';
import { OD_ENUM_ACTION } from '@/constants/od-enum/global';
import {
  BE_ORDER_BY_KEYWORD_DESC,
  BE_ORDER_BY_KEYWORD_NULLSFIRST,
  BE_ORDER_BY_KEYWORD_NULLSLAST,
} from '@/constants/orderBy';
import {
  IApiGetResourceRequest,
  IApiGetResourceRequestQuery,
  IApiGetResourcesRequest,
  IApiGetResourcesRequestQuery,
  IApiMethod,
  IApiRequest,
  IPerformCreate,
  IPerformDelete,
  IPerformDeleteByFilter,
  IPerformUpdate,
  IPerformUpdateByFilter,
} from '@/interfaces/api';
import { IncrementalId } from '@/interfaces/id';
import { handleInvalidAccessToken } from '@/redux/AuthProvider/actions';
import { apiDelete, apiGet, apiPatch, apiPost } from '@/services/AJAX';
import convertFieldsToQuery from '@/utils/convertFieldsToQuery';
import getStore from '@/utils/getStore';

import sanitizeCookie from '../../utils/sanitizeCookie';
import { IXT_API_URL } from './constants';

export const performCreate: IPerformCreate = data => ({ action: OD_ENUM_ACTION.CREATE, data });
export const performUpdate: IPerformUpdate = (id, data) => ({ action: OD_ENUM_ACTION.UPDATE, id, data });
export const performDelete: IPerformDelete = id => ({ action: OD_ENUM_ACTION.DELETE, id });

export const performUpdateByFilter: IPerformUpdateByFilter = (filter, data) => ({
  action: OD_ENUM_ACTION.UPDATE,
  filter,
  data,
});
export const performDeleteByFilter: IPerformDeleteByFilter = filter => ({ action: OD_ENUM_ACTION.DELETE, filter });

export const mapNullsFirstToKeyword = (nullsFirst?: boolean): string => {
  if (typeof nullsFirst === 'boolean') {
    return ` ${nullsFirst ? BE_ORDER_BY_KEYWORD_NULLSFIRST : BE_ORDER_BY_KEYWORD_NULLSLAST}`;
  }
  return '';
};

const ACCESS_TOKEN_KEY = 'access-token';

const getAccessToken = (): string | undefined => {
  const accessTokenCookie = document.cookie
    .split('; ')
    .find(row => row.startsWith(ACCESS_TOKEN_KEY))
    ?.split('=')[1];

  return accessTokenCookie;
};

const CSRF_TOKEN_HEADER = 'Csrf-Token';
const CSRF_TOKEN_KEY = 'CSRF-TOKEN';

const getOrCreateCSRFToken = (): string => {
  const csrfTokenCookie = document.cookie
    .split('; ')
    .find(row => row.startsWith(CSRF_TOKEN_KEY))
    ?.split('=')[1];

  if (!csrfTokenCookie) {
    return uuidV4();
  }

  return csrfTokenCookie;
};

const addCSRFTokenToCookie = (csrfToken: string): void => {
  if (!document) {
    return;
  }

  const subdomain = IXT_API_URL.slice(Math.max(IXT_API_URL.indexOf('.'), 0));
  document.cookie = sanitizeCookie(`${CSRF_TOKEN_KEY}=${csrfToken};domain=${subdomain};path=/`);
};

export const getAttachmentUrl = (attachmentId: IncrementalId): string => {
  const csrfToken = getOrCreateCSRFToken();
  addCSRFTokenToCookie(csrfToken);
  return `${IXT_API_URL}/internal/attachments/${attachmentId}/download?x=${csrfToken}`;
};

const ixtResponseHandler = response => {
  const selfDefinedStatus = response?.status;
  if (isNumber(selfDefinedStatus)) {
    if (selfDefinedStatus === STATUS_SUCCESS) {
      return response;
    }
    throw response;
  }
  return response;
};

const ixtExceptionHandler = error => {
  const { response } = error;
  if (response?.status === ACCESS_TOKEN_ERROR) {
    const store = getStore();
    if (store) {
      store.dispatch(handleInvalidAccessToken());
    }
  }
  throw error;
};

export const ixtApiWrapper =
  <R>(apiMethod: IApiMethod<R>) =>
  ({ headers = {}, ...attributes }: Partial<IApiRequest<R>>) => {
    const csrfToken = getOrCreateCSRFToken();
    addCSRFTokenToCookie(csrfToken);

    const accessToken = getAccessToken();

    return apiMethod({
      baseUrl: `${IXT_API_URL}/internal`,
      withCredentials: true,
      headers: {
        [CSRF_TOKEN_HEADER]: csrfToken,
        WEBPAGE: window.location.href,
        ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
        ...headers,
      },
      ...attributes,
    }).pipe(map(ixtResponseHandler), catchError(ixtExceptionHandler));
  };

// Basic CRUD
export const ixtApiGet: IApiMethod<Record<string, any>> = ixtApiWrapper(apiGet);
export const ixtApiPost: IApiMethod<Record<string, any> | FormData | Blob> = ixtApiWrapper(apiPost);
export const ixtApiPatch: IApiMethod<Record<string, any>> = ixtApiWrapper(apiPatch);
export const ixtApiDelete: IApiMethod<void> = ixtApiWrapper(apiDelete);

export const ixtApiGetResource: IApiMethod<IApiGetResourceRequest> = ({ request, ...attributes }) => {
  let requestQuery: IApiGetResourceRequestQuery | undefined;

  // Customize request payload
  if (request) {
    const { fields } = request;

    requestQuery = {
      fields: convertFieldsToQuery(fields),
    };
  }

  return ixtApiWrapper(apiGet)({
    ...attributes,
    request: requestQuery,
  });
};

export const ixtApiGetResources: IApiMethod<IApiGetResourcesRequest> = ({ request, ...attributes }) => {
  let requestQuery: IApiGetResourcesRequestQuery | undefined;

  // Customize request payload
  if (request) {
    const { fields, filter, orderBy, ascending, nullsFirst, ...restRequestAttributes } = request;
    const orderByQueryString = `${orderBy || ''}${
      ascending ? '' : ` ${BE_ORDER_BY_KEYWORD_DESC}${mapNullsFirstToKeyword(nullsFirst)}`
    }`;

    requestQuery = {
      ...restRequestAttributes,
      fields: convertFieldsToQuery(fields),
      ...(filter ? { filter: JSON.stringify(filter) } : {}),
      ...(orderBy ? { order_by: orderByQueryString } : {}),
    };
  }

  return ixtApiWrapper(apiGet)({
    ...attributes,
    request: requestQuery,
  });
};
