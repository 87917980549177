import { getTimezoneText } from '@ixt/utilities';
import { createSelector } from 'reselect';

import { ALL_PERMISSION_MODULES } from '@/constants/permission';
import SCHEMA_CALLING_CODE from '@/constants/schema/callingCode';
import SCHEMA_COUNTRY from '@/constants/schema/country';
import SCHEMA_CURRENCY from '@/constants/schema/currency';
import SCHEMA_MARKET from '@/constants/schema/market';
import SCHEMA_PLATFORM from '@/constants/schema/platform';
import SCHEMA_TIMEZONE from '@/constants/schema/timezone';
import { IncrementalId } from '@/interfaces/id';
import { IOption } from '@/interfaces/option';

import { myModulesSelector } from '../AuthProvider/selectors';
import { IApiCurrency, MiscProviderState } from './interfaces';

// -------------------------------------
//   Calling Code
// -------------------------------------

const selectCallingCode = (state): MiscProviderState['callingCode'] => state.misc.callingCode;

export const callingCodeListSelector = createSelector(selectCallingCode, content => content.list);

export const callingCodeSelectionOptionsSelector = createSelector(callingCodeListSelector, content => {
  const options: IOption[] = content.map(item => ({
    value: item[SCHEMA_CALLING_CODE.CODE] ?? '',
    label: item[SCHEMA_CALLING_CODE.CODE] ?? '',
  }));

  return options;
});

// -------------------------------------
//   Country
// -------------------------------------

const selectCountry = (state): MiscProviderState['country'] => state.misc.country;

export const countryListSelector = createSelector(selectCountry, content => content.list);

export const countrySelectionOptionsSelector = createSelector(countryListSelector, content => {
  const options: IOption[] = content.map(item => ({
    value: item[SCHEMA_COUNTRY.ID],
    label: item[SCHEMA_COUNTRY.NAME] ?? '',
  }));

  return options;
});

// -------------------------------------
//   Currency
// -------------------------------------

const selectCurrency = (state): MiscProviderState['currency'] => state.misc.currency;

export const currencyListSelector = createSelector(selectCurrency, content => content.list);

export const currencyMapSelector = createSelector(currencyListSelector, content =>
  content.reduce(
    (prevMap, currency) => ({ ...prevMap, [currency[SCHEMA_CURRENCY.ID]]: currency }),
    {} as { [id: number]: IApiCurrency }
  )
);

export const currencySelectionOptionsSelector = createSelector(currencyListSelector, content => {
  const options: IOption<IncrementalId>[] = content.map(item => ({
    value: item[SCHEMA_CURRENCY.ID],
    label: item[SCHEMA_CURRENCY.NAME],
  }));

  return options;
});

export const makeCurrencyNameSelector = currencyId =>
  createSelector(currencyMapSelector, content => content[currencyId]?.[SCHEMA_CURRENCY.NAME] || '');

// -------------------------------------
//   Market
// -------------------------------------

const selectMarket = (state): MiscProviderState['market'] => state.misc.market;

export const marketListSelector = createSelector(selectMarket, content => content.list);

export const marketSelectionOptionsSelector = createSelector(marketListSelector, content => {
  const options: IOption[] = content.map(item => ({
    value: item[SCHEMA_MARKET.ID],
    label: item[SCHEMA_MARKET.NAME] ?? '',
  }));

  return options;
});

// -------------------------------------
//   Alert
// -------------------------------------

const selectAlert = (state): MiscProviderState['alert'] => state.misc.alert;

export const alertListSelector = createSelector(selectAlert, content => content.list);

// -------------------------------------
//   Sidebar
// -------------------------------------

const selectSidebar = (state): MiscProviderState['sidebar'] => state.misc.sidebar;

const defaultSidebarModuleSelector = createSelector(myModulesSelector, content =>
  ALL_PERMISSION_MODULES.find(m => content.has(m))
);

export const sidebarModuleSelector = createSelector(
  selectSidebar,
  defaultSidebarModuleSelector,
  (content, defaultModule) => ({
    module: content.module || defaultModule,
    subModule: content.subModule,
  })
);

// -------------------------------------
//   Timezone
// -------------------------------------

const selectTimezone = (state): MiscProviderState['timezone'] => state.misc.timezone;

export const timezoneListSelector = createSelector(selectTimezone, content => content.list);

export const timezoneSelectionOptionsSelector = createSelector(timezoneListSelector, content => {
  const options: IOption[] = content.map(timezone => ({
    value: timezone[SCHEMA_TIMEZONE.ID],
    label: getTimezoneText(timezone[SCHEMA_TIMEZONE.NAME], timezone[SCHEMA_TIMEZONE.OFFSET]),
  }));

  return options;
});

// -------------------------------------
//   Platform
// -------------------------------------

const selectPlatform = (state): MiscProviderState['platform'] => state.misc.platform;

export const platformListSelector = createSelector(selectPlatform, content => content.list);

export const platformSelectionOptionsSelector = createSelector(platformListSelector, content => {
  const options: IOption[] = content.map(item => ({
    value: item[SCHEMA_PLATFORM.ID],
    label: `${item[SCHEMA_PLATFORM.NAME]}`,
  }));

  return options;
});

// -------------------------------------
//   Locale (platform specific and all)
// -------------------------------------

const selectLocale = (state): MiscProviderState['locale'] => state.misc.locale;

export const localeListSelector = createSelector(selectLocale, content => content.list);
