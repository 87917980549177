import { combineReducers, createReducer } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

import { OD_VALUE_LOCALE__CODE } from '@/constants/od-value/locale';
import {
  MODULE_APPLICATION,
  MODULE_CAMPAIGN,
  MODULE_MEMBER,
  MODULE_POLICY,
  MODULE_PRODUCT,
  MODULE_SYSTEM,
} from '@/constants/permission';
import {
  campaignRoot,
  commercialApplicationRoot,
  commercialPolicyRoot,
  libraryRoot,
  memberRoot,
  personalApplicationRoot,
  personalPolicyRoot,
  productRoot,
  riderRoot,
  systemModuleRoots,
} from '@/constants/urls';
import { ILocale as INormalizedLocale } from '@/interfaces/locale';

import {
  changedRoute,
  closeAlert,
  popAlert,
  pushAlert,
  receivedCallingCodeList,
  receivedCountryList,
  receivedCurrencyList,
  receivedLocaleList,
  receivedMarketList,
  receivedPlatformList,
  receivedTimezoneList,
} from './actions';
import { MiscProviderState } from './interfaces';

const callingCodeReducer = createReducer({ list: [] } as MiscProviderState['callingCode'], builder => {
  builder.addCase(receivedCallingCodeList, (state, { payload }) => {
    state.list = payload;
  });
});

const countryReducer = createReducer({ list: [] } as MiscProviderState['country'], builder => {
  builder.addCase(receivedCountryList, (state, { payload }) => {
    state.list = payload;
  });
});

const currencyReducer = createReducer({ list: [] } as MiscProviderState['currency'], builder => {
  builder.addCase(receivedCurrencyList, (state, { payload }) => {
    state.list = payload;
  });
});

const marketReducer = createReducer({ list: [] } as MiscProviderState['market'], builder => {
  builder.addCase(receivedMarketList, (state, { payload }) => {
    state.list = payload;
  });
});

const alertReducer = createReducer({ list: [] } as MiscProviderState['alert'], builder => {
  builder
    .addCase(pushAlert, (state, { payload }) => {
      state.list.push({ id: uuid(), show: true, ...payload });
    })
    .addCase(closeAlert, (state, { payload }) => {
      const index = state.list.findIndex(item => item.id === payload);
      if (index >= 0) {
        state.list[index].show = false;
      }
    })
    .addCase(popAlert, (state, { payload }) => {
      state.list = state.list.filter(item => item.id !== payload);
    });
});

const sidebarReducer = createReducer({ module: null } as MiscProviderState['sidebar'], builder => {
  builder.addCase(changedRoute, (state, { payload: pathname }) => {
    if (pathname.startsWith(productRoot) || pathname.startsWith(riderRoot) || pathname.startsWith(libraryRoot)) {
      state.module = MODULE_PRODUCT;
    } else if (pathname.startsWith(personalApplicationRoot) || pathname.startsWith(commercialApplicationRoot)) {
      state.module = MODULE_APPLICATION;
    } else if (pathname.startsWith(personalPolicyRoot) || pathname.startsWith(commercialPolicyRoot)) {
      state.module = MODULE_POLICY;
    } else if (pathname.startsWith(memberRoot)) {
      state.module = MODULE_MEMBER;
    } else if (pathname.startsWith(campaignRoot)) {
      state.module = MODULE_CAMPAIGN;
    } else if (systemModuleRoots.some(root => pathname.startsWith(root))) {
      state.module = MODULE_SYSTEM;
      state.subModule = null;
    }
  });
});

const timezoneReducer = createReducer({ list: [] } as MiscProviderState['timezone'], builder => {
  builder.addCase(receivedTimezoneList, (state, { payload }) => {
    state.list = payload;
  });
});

const platformReducer = createReducer({ list: [] } as MiscProviderState['platform'], builder => {
  builder.addCase(receivedPlatformList, (state, { payload }) => {
    state.list = payload;
  });
});

const localeReducer = createReducer({ list: [] } as MiscProviderState['locale'], builder => {
  builder.addCase(receivedLocaleList, (state, { payload }) => {
    const localeList = payload.reduce((list, locale) => {
      if (locale.code) {
        list.push({
          id: locale.id,
          code: locale.code as OD_VALUE_LOCALE__CODE,
        });
      }

      return list;
    }, [] as INormalizedLocale[]);

    state.list = localeList;
  });
});

export default combineReducers({
  callingCode: callingCodeReducer,
  country: countryReducer,
  currency: currencyReducer,
  market: marketReducer,
  alert: alertReducer,
  sidebar: sidebarReducer,
  timezone: timezoneReducer,
  platform: platformReducer,
  locale: localeReducer,
});
