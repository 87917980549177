import { css } from 'styled-components';

import Colors from '@/constants/colors';

import buttonCssMixin from './buttonCssMixin';

const containedButtonCssMixin = css<{ disabled?: boolean }>`
  ${buttonCssMixin};
  min-height: 2.5rem; /* workaround until all ContainedButton are provided height */
  padding-top: unset;
  padding-bottom: unset;
  border: 0;
  transition-property: color, background-color;
  transition-duration: 0.5s;

  ${props =>
    props.disabled &&
    css`
      color: ${Colors.BLACK_025};
      background-color: ${Colors.BLACK_009};
    `}
`;

export default containedButtonCssMixin;
