import { captureException, init, withScope } from '@sentry/browser';
import { throwError } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';

import { isPopularBrowser, isPopularOS } from '@/services/Device';
import getEnvVar from '@/utils/getEnvVar';
import { getCurrentLanguage } from '@/utils/nextRouter';

const CONFIG_LINK_PROD = getEnvVar('SENTRY_DSN'); // DSN

let isSentryEnabled = false;
let userInfo = null;

const isWhiteListError = apiError => {
  const { request, status } = apiError;
  const { method, url = '' } = request || {};

  /* ignore unauthorized error */
  if (status === 401) {
    return true;
  }

  /* ignore polling notification error */
  if (url.includes('/me/notifications') && method === 'GET') {
    return true;
  }

  return false;
};

interface ICaptureSentryException {
  error?: Error;
  tags?: Array<[key: string, value: any]>;
  extra?: { [key: string]: any };
  fingerprint?: string[];
}

export function captureSentryException({ error, tags, extra, fingerprint }: ICaptureSentryException) {
  if (!isSentryEnabled) {
    return;
  }

  if (error) {
    withScope(scope => {
      const targetFingerprint = fingerprint || ['{{ default }}'];

      scope.setTag('ssr', typeof window === 'undefined');
      scope.setTag('lng', getCurrentLanguage());

      if (tags) {
        tags.forEach(tagKeyValue => {
          scope.setTag(tagKeyValue[0], tagKeyValue[1]);
        });
      }

      if (extra) {
        Object.entries(extra).forEach(([key, value]) => {
          scope.setExtra(key, value);
        });
      }

      scope.setFingerprint(targetFingerprint);
      captureException(error);
    });
  }
}

export const setSentryUserInfo = info => {
  userInfo = info;
};

export const processApiError = (apiError: AjaxError) => {
  if (!isSentryEnabled) {
    return throwError(apiError);
  }

  const errorForSentry = { ...apiError };
  const { message, request, response, status } = apiError;
  const { method, url, body } = request || {};
  const { status: responseStatus, message: responseMessage } = response || {};

  let messageForSentry = message || 'API error';
  let fingerprint = ['{{ default }}'];

  if (method || url || responseMessage || status) {
    messageForSentry = `${method || ''} ${status || ''} ${responseMessage || ''} ${url || ''}`;
    errorForSentry.message = messageForSentry;
    fingerprint = [`${method || ''}`, `${status || ''}`, `${responseMessage || ''}`, `${url || ''}`];
  }

  if (!isWhiteListError(apiError)) {
    captureSentryException({
      error: new Error(messageForSentry),
      tags: [
        ['errorType', 'API'],
        ['originMessage', message || 'none'],
      ],
      extra: {
        url,
        status: responseStatus,
        message: responseMessage,
        ...(body ? { body: `${body}` } : {}),
        user: JSON.stringify(userInfo),
      },
      fingerprint,
    });
  }

  return throwError(apiError);
};

export function initSentry(): void {
  if (isPopularBrowser() && isPopularOS() && CONFIG_LINK_PROD) {
    isSentryEnabled = true;
    init({
      dsn: CONFIG_LINK_PROD,
      release: process.env.buildId,
      environment: getEnvVar('HOST_ENV'),
    });
  }
}
