/**
 * Combine all reducers in this file and export the combined reducers.
 */

import authReducer from './AuthProvider/reducers';
import miscReducer from './MiscProvider/reducers';
import notificationReducer from './NotificationProvider/reducers';
import remarkReducer from './RemarkProvider/reducers';

export default {
  auth: authReducer,
  misc: miscReducer,
  notification: notificationReducer,
  remark: remarkReducer,
};
