import getEnvVar from '@/utils/getEnvVar';

export const IXT_API_URL = getEnvVar('IXT_API_URL', 'https://core-gw.dev.theixt.com');
export const RESOURCE_INTERNAL_API_URL = getEnvVar(
  'RESOURCE_INTERNAL_API_URL',
  'https://resource-internal-api.dev.theixt.com'
);
export const CORE_WEB_URL = getEnvVar('CORE_WEB_URL', 'https://internal-web.dev.theixt.com/');
export const AUTH_WEB_URL = getEnvVar('AUTH_WEB_URL', 'https://auth.dev.theixt.com');
export const SYSTEM_WEB_URL = getEnvVar('SYSTEM_WEB_URL', 'https://ixt-system.dev.theixt.com');
export const RESOURCE_WEB_URL = getEnvVar('RESOURCE_WEB_URL', 'https://ixt-resource.dev.theixt.com');
export const PRM_WEB_URL = getEnvVar('PRM_WEB_URL', 'https://ixt-prm.dev.theixt.com');
export const CLAIM_WEB_URL = getEnvVar('CLAIM_WEB_URL', 'https://ixt-claim.dev.theixt.com');
export const UTILITY_WEB_URL = getEnvVar('UTILITY_WEB_URL', `https://ixt-utility.dev.theixt.com`);
export const CMS_WEB_URL = getEnvVar('CMS_WEB_URL', 'https://cms.dev.theixt.com');
export const CSP_HOSTNAME = getEnvVar('CSP_HOSTNAME', '*.theixt.com');
