import { FC, useEffect, useRef } from 'react';
import styled, { css, keyframes } from 'styled-components';

import svgFail from '@/assets/alert/alert-fail.svg';
import svgNegativeSuccess from '@/assets/alert/alert-negative-success.svg';
import svgSuccess from '@/assets/alert/alert-success.svg';
import Portal from '@/components/Portal';
import SVG from '@/components/SVG';
import { ALERT_TYPE } from '@/constants/alert';
import Colors from '@/constants/colors';
import Typography from '@/constants/typography';
import ZIndices from '@/constants/zIndices';
import delayUnmounting from '@/decorators/delayUnmounting';

const defaultTimeout = 4000;
const defaultDelay = 300;

const AnimationShowFailAlert = keyframes`
  0% {
    opacity: 0;
    transform: translateX(50%);
  }
  40% {
    opacity: 1;
    transform: translateX(50%);
  }
  60% {
    transform: translateX(47%);
  }
  70% {
    transform: translateX(53%);
  }
  100% {
    transform: translateX(50%);
  }
`;

const AnimationShowAlert = keyframes`
  0% {
    top: -2.75rem;
    opacity: 0;
  }
  100% {
    opacity: 1;
    top: 4rem;
  }
`;

const AnimationHide = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

const Alert = styled.div<{ $show: boolean; $duration: number; $type: ALERT_TYPE }>`
  padding: 0.75rem 1rem 0.75rem 1.125rem;
  background: ${Colors.BLACK_065};
  border-radius: 0.5rem;
  position: fixed;
  right: 50%;
  transform: translateX(50%);
  display: flex;
  align-items: center;
  top: 4rem;
  z-index: ${ZIndices.Alert};
  ${props =>
    props.$type === ALERT_TYPE.SUCCESS || props.$type === ALERT_TYPE.NEGATIVE_SUCCESS
      ? css`
          animation: ${props.$show ? AnimationShowAlert : AnimationHide} ${props.$duration}ms ease-in;
        `
      : css`
          animation: ${props.$show ? AnimationShowFailAlert : AnimationHide} ${props.$duration}ms ease-out;
        `}
`;

const AlertText = styled.span.attrs({ className: Typography.BODY_S })`
  color: ${Colors.WHITE_100};
  padding-left: 1rem;
`;

const Icon = styled(SVG)`
  height: 1.5rem;
  width: 1.5rem;
`;

const mapTypeToIcon = {
  [ALERT_TYPE.SUCCESS]: svgSuccess,
  [ALERT_TYPE.FAIL]: svgFail,
  [ALERT_TYPE.NEGATIVE_SUCCESS]: svgNegativeSuccess,
};

interface Props {
  id: string;

  /** Whether to display the alert banner */
  show: boolean;

  /** The duration of the animation */
  delay?: number;

  /** Time in seconds before Notification is closed. */
  timeout?: number;
  type: ALERT_TYPE;

  /** The text displayed in the alert banner */
  text: string;
  onClose: (id: string) => void;
  onUnmount?: (id: string) => void;
}

const AlertBannerWithoutUnmount: FC<Props> = ({
  id,
  show,
  delay = defaultDelay,
  timeout = defaultTimeout,
  type,
  text,
  onClose,
  onUnmount,
}) => {
  const prevPropsRef = useRef({
    text,
    type,
  });

  useEffect(() => {
    if (show) {
      prevPropsRef.current = {
        text,
        type,
      };
    }
  }, [show, text, type]);

  useEffect(() => {
    if (show) {
      const timeoutId = setTimeout(() => {
        onClose(id);
      }, timeout);
      return () => clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, id]);

  useEffect(() => () => onUnmount?.(id), [onUnmount, id]);

  return (
    <Portal>
      <Alert id={id} $show={show} $duration={delay} $type={prevPropsRef.current.type}>
        <Icon src={mapTypeToIcon[prevPropsRef.current.type]} />
        <AlertText>{prevPropsRef.current.text}</AlertText>
      </Alert>
    </Portal>
  );
};

export const AlertBanner = delayUnmounting(AlertBannerWithoutUnmount, defaultDelay);
