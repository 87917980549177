import { map } from 'rxjs/operators';

import {
  ICreateMeNotificationsReadRequest,
  IRetrieveMeNotificationsQueries,
  IRetrieveMeNotificationsResponse,
} from '@/platforms/interfaces/odg/od-api';
import { INotification, NotificationData } from '@/redux/NotificationProvider/interfaces';
import { ixtApiGet, ixtApiPost } from '@/services/OneDegree/utils';
import getSortedDataByField from '@/utils/getSortedDataByField';

import {
  mapNotificationTypeToDescriptionI18nKey,
  mapNotificationTypeToLinkInfo,
  mapNotificationTypeToTitleI18nKey,
} from './utils';

export const ixtApiGetMyNotifications = (request?: IRetrieveMeNotificationsQueries) =>
  ixtApiGet<IRetrieveMeNotificationsResponse['data']>({
    url: '/me/notifications',
    request,
  }).pipe(
    map(response =>
      // A user can view a list of notifications in the order of :
      // is_read (False > True), then Create datetime (Descending)
      getSortedDataByField<IRetrieveMeNotificationsResponse['data'][number]>(
        response.data,
        'is_read',
        true
      ).map<INotification>(notification => {
        const { id, type, data = {}, create_time, is_read } = notification;
        return {
          id,
          titleI18nKey: mapNotificationTypeToTitleI18nKey(type),
          descriptionI18nKey: mapNotificationTypeToDescriptionI18nKey(type),
          descriptionI18nValues: data,
          createTime: create_time,
          linkInfo: mapNotificationTypeToLinkInfo(type, data as NotificationData),
          isRead: is_read ?? false,
        };
      })
    )
  );

export const ixtApiReadMyNotifications = (request?: ICreateMeNotificationsReadRequest) =>
  ixtApiPost({
    url: '/me/notifications/read',
    request,
  });
