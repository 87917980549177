import { createAction } from '@reduxjs/toolkit';
import { AjaxError } from 'rxjs/ajax';

import {
  ICreateLoginResponse,
  ICreateUpdatePasswordRequest,
  IRetrieveAccessTokenResponse,
  IRetrieveActiveAuthenticationsResponse,
  IUser,
} from '@/interfaces/od-api';
import { createCallbacksAction } from '@/utils/redux';

export const signIn = createCallbacksAction<
  { account: string; password: string; authenticationType: string },
  ICreateLoginResponse['data'],
  AjaxError
>('app/auth/SIGN_IN');
export const signInError = createAction('app/auth/SIGN_IN_ERROR');
export const signInSuccess = createAction('app/auth/SIGN_IN_SUCCESS');

export const handleSessionTimeout = createAction<Error | AjaxError>('app/auth/HANDLE_SESSION_TIMEOUT');
export const setIsSessionTimeoutTrue = createAction('app/auth/SET_SESSION_TIMEOUT_TRUE');
export const setIsSessionTimeoutFalse = createAction('app/auth/SET_SESSION_TIMEOUT_FALSE');
export const setIsPermissionDeniedFalse = createAction('app/auth/SET_PERMISSION_DENIED_FALSE');

export const getMe = createAction('app/auth/GET_ME');
export const getMeError = createAction<Error>('app/auth/GET_ME_ERROR');
export const receivedMe = createAction<IUser>('app/auth/RECEIVED_ME');

// FIXME: remove redundant Page ME and actions
export const sendResetPasswordEmail = createCallbacksAction('app/auth/SEND_RESET_PASSWORD_EMAIL');
export const sendResetPasswordEmailSucceed = createAction('app/auth/SEND_RESET_PASSWORD_EMAIL_SUCCEED');
export const sendResetPasswordEmailError = createAction('app/auth/SEND_RESET_PASSWORD_EMAIL_ERROR');

export const updatePassword = createCallbacksAction<ICreateUpdatePasswordRequest>('app/auth/UPDATE_PASSWORD');
export const updatePasswordSucceed = createAction('app/auth/UPDATE_PASSWORD_SUCCEED');
export const updatePasswordError = createAction('app/auth/UPDATE_PASSWORD_ERROR');

export const getAccessToken = createAction('app/auth/GET_ACCESS_TOKEN');
export const getAccessTokenError = createAction('app/auth/GET_ACCESS_TOKEN_ERROR');
export const receivedAccessToken = createAction('app/auth/RECEIVED_ACCESS_TOKEN');

export const extendAccessToken = createAction<IRetrieveAccessTokenResponse['data']>('app/auth/EXTEND_ACCESS_TOKEN');
export const renewExtendAccessToken = createAction('app/auth/RENEW_EXTEND_ACCESS_TOKEN');
export const cancelExtendAccessToken = createAction('app/auth/CANCEL_EXTEND_ACCESS_TOKEN');

export const handleInvalidAccessToken = createAction('app/auth/HANDLE_INVALID_ACCESS_TOKEN');

export const getActiveAuthentications = createAction('app/auth/GET_ACTIVE_AUTHENTICATIONS');
export const getActiveAuthenticationsError = createAction('app/auth/GET_ACTIVE_AUTHENTICATIONS_ERROR');
export const receivedActiveAuthentications = createAction<IRetrieveActiveAuthenticationsResponse['data']>(
  'app/auth/RECEIVED_ACTIVE_AUTHENTICATIONS'
);
