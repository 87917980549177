const PLATFORM_ODG = 'odg';
const PLATFORM_CIGNA_TAIWAN = 'cignatw';
const PLATFORM_FIRST_INS = 'firstins';

const DEFAULT_PLATFORM = PLATFORM_ODG;

module.exports = {
  PLATFORM_ODG,
  PLATFORM_CIGNA_TAIWAN,
  PLATFORM_FIRST_INS,
  ALL_PLATFORMS: [PLATFORM_ODG, PLATFORM_CIGNA_TAIWAN, PLATFORM_FIRST_INS],
  DEFAULT_PLATFORM,
};
