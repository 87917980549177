import { useCallback, useEffect } from 'react';

interface Props {
  onVisible: () => void;
  onHidden?: () => void;
}

const useWatchVisibilityChange = ({ onVisible, onHidden }: Props) => {
  const handleVisibilityChange = useCallback(() => {
    if (document?.visibilityState === 'visible') {
      onVisible();
    } else if (document?.visibilityState === 'hidden') {
      onHidden?.();
    }
  }, [onHidden, onVisible]);

  useEffect(() => {
    window?.addEventListener('visibilitychange', handleVisibilityChange, false);

    return () => {
      window?.removeEventListener('visibilitychange', handleVisibilityChange, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useWatchVisibilityChange;
